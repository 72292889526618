// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editSalesCollection, getAllSalesCollections, getAllSalesCollectionsPerCustomer } from "../request/salesCollectionRequest";



export const fetchAllSalesCollection = createAsyncThunk('import/fetchAllSalesCollection', async (data) => {

    const response = await getAllSalesCollections(data)
    return response.data.items
})

export const fetchAllSalesCollectionPerCustomer = createAsyncThunk('import/fetchAllSalesCollectionPerCustomer', async (data) => {

    const response = await getAllSalesCollectionsPerCustomer(data)
    return response.data.items
})

export const updateSalesCollection = createAsyncThunk('import/updateSalesCollection', async (payload, thunkAPI) => {

    try {
        const response = await editSalesCollection(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    info_per_customer: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesCollectionSlice = createSlice({
    name: "salesCollectionSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Collection :
        builder
            .addCase(fetchAllSalesCollection.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesCollection.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesCollection.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Fetch Sales Per Customer :
        builder
            .addCase(fetchAllSalesCollectionPerCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "get_customer", errors: {} }
            })
            .addCase(fetchAllSalesCollectionPerCustomer.fulfilled, (state, action) => {
                return { ...state, info_per_customer: action.payload, type: "get_customer", status: "succeded" }
            })
            .addCase(fetchAllSalesCollectionPerCustomer.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get_customer", status: "failed" }
            })



        //Edit Sales Collection :
        builder
            .addCase(updateSalesCollection.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesCollection.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesCollection.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })





    }
})

export default salesCollectionSlice.reducer;