// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addNomenclature, deleteNomenclature, editNomenclature, getAllNomenclature } from "../request/nomenclatureRequest";


export const fetchAllNomclatures = createAsyncThunk('import/fetchAllNomclatures', async () => {

    const response = await getAllNomenclature()
    return response.data.items
})


export const newNomenclature = createAsyncThunk('import/newNomenclature', async (payload, thunkAPI) => {

    try {
        const response = await addNomenclature(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateNomenclature = createAsyncThunk('import/updateNomenclature', async (payload, thunkAPI) => {

    try {
        const response = await editNomenclature(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeNomenclature = createAsyncThunk('family/removeNomenclature', async (payload) => {
    const response = await deleteNomenclature(payload)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const nomenclatureSlice = createSlice({
    name: "nomenclature",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchAllNomclatures.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllNomclatures.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllNomclatures.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //New Nomenclature :
        builder
            .addCase(newNomenclature.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newNomenclature.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newNomenclature.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Nomenclature :
        builder
            .addCase(updateNomenclature.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateNomenclature.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateNomenclature.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Remove Nomenclature :
        builder
            .addCase(removeNomenclature.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeNomenclature.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeNomenclature.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })

    }
})

export default nomenclatureSlice.reducer;