// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addShipping, getAllShippings, editShipping, deleteShipping } from "../request/dropshippingRequest";

export const fetchAllShippings = createAsyncThunk('dropshipping/fetchAllShippings', async () => {

    const response = await getAllShippings()
    return response.data.items
})

export const newShipping = createAsyncThunk('dropshipping/newShipping', async (payload, thunkAPI) => {

    try {
        const response = await addShipping(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateShipping = createAsyncThunk('dropshipping/updateShipping', async (data) => {
    var response = await editShipping(data)
    return {}

})

export const removeShipping = createAsyncThunk('dropshipping/removeShipping', async (data) => {
    var response = await deleteShipping(data)
    return {}
})





const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const dropshippingSlice = createSlice({
    name: "dropshipping",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Shippings :

        builder
            .addCase(fetchAllShippings.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllShippings.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllShippings.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Shipping :
        builder
            .addCase(newShipping.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newShipping.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newShipping.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Dropshipping :
        builder
            .addCase(updateShipping.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateShipping.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateShipping.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "put", status: "failed" }
            })

        //Remove Dropshipping :
        builder
            .addCase(removeShipping.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeShipping.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeShipping.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })

    }
})

export default dropshippingSlice.reducer;