// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addProgressStatus, deleteProgressStatus, editProgressStatus, getAllProgressStatus } from "../request/StatusProgress";

export const fetchAllProgressStatus = createAsyncThunk('ProgressStatus/fetchAllProgressStatus', async () => {

    const response = await getAllProgressStatus()
    return response.data.items
})

export const newProgressStatus = createAsyncThunk('ProgressStatus/newProgressStatus', async (payload, thunkAPI) => {
   
    try {
        const response = await addProgressStatus(payload)
       

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateProgressStatus = createAsyncThunk(
    'ProgressStatus/updateProgressStatus',
    async (payload, thunkAPI) => {
      try {
   
        const response = await editProgressStatus(payload); 
        return thunkAPI.fulfillWithValue(payload);
      } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
      }
    }
  )  

export const removeProgressStatus = createAsyncThunk('ProgressStatus/removeProgressStatus', async (payload, thunkAPI) => {

    try {
        const response = await deleteProgressStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const ProgressStatusSlice = createSlice({
    name: "ProgressStatus",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All ProgressStatuss :

        builder
            .addCase(fetchAllProgressStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllProgressStatus.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllProgressStatus.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New ProgressStatus :
        builder
            .addCase(newProgressStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newProgressStatus.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newProgressStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update ProgressStatus :
        builder
            .addCase(updateProgressStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateProgressStatus.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateProgressStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Remove ProgressStatus :
        builder
            .addCase(removeProgressStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeProgressStatus.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeProgressStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})
export default ProgressStatusSlice.reducer;