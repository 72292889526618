//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"

const getHeaders = (token) => ({
    'Authorization': 'Bearer ' + token,
    'x-tenant-id': localStorage.getItem('tenantId'),
    'Content-Type': 'application/json'
});

// Get all Bill of Materials
export const getAllBillofMaterials = async () => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/billofmaterial`
    });
};

// Get a specific Bill of Material by ID
export const getBillofMaterial = async (id) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/billofmaterial/${id}`
    });
};

// Create a new Bill of Material
export const addBillofMaterial = async (payload) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/billofmaterial`,
        data: payload
    });
};

// Update an existing Bill of Material
export const editBillofMaterial = async (payload) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/billofmaterial/${payload.id}`,
        data: payload
        
    });
};

// Delete a Bill of Material
export const deleteBillofMaterial = async (id) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/billofmaterial/${id}`,
    });
};
