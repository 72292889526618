// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesPerson, deleteSalesPerson, editSalesPerson, getAllSalesPersonel, getSalesPerson } from "../request/salesPersonRequest";



export const fetchAllSalesPerson = createAsyncThunk('import/fetchAllSalesPerson', async (data) => {

    const response = await getAllSalesPersonel(data)
    return response.data.items
})

export const searchSalesPerson = createAsyncThunk('import/searchSalesPerson', async (payload) => {

    const response = await getSalesPerson(payload)
    return response.data
})


export const newSalesPerson = createAsyncThunk('import/newSalesPerson', async (payload, thunkAPI) => {

    try {
        const response = await addSalesPerson(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSalesPerson = createAsyncThunk('import/updateSalesPerson', async (payload, thunkAPI) => {

    try {
        const response = await editSalesPerson(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeSalesPerson = createAsyncThunk('import/removeSalesPerson', async (payload) => {
    const response = await deleteSalesPerson(payload)
    return {}
})

const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesPersonSlice = createSlice({
    name: "salesPersonSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Person :
        builder
            .addCase(fetchAllSalesPerson.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesPerson.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesPerson.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Person :
        builder
            .addCase(searchSalesPerson.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesPerson.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesPerson.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Sales Person :
        builder
            .addCase(newSalesPerson.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSalesPerson.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSalesPerson.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit bank :
        builder
            .addCase(updateSalesPerson.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesPerson.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesPerson.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Sales Person :
        builder
            .addCase(removeSalesPerson.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesPerson.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesPerson.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


    }
})

export default salesPersonSlice.reducer;