// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBankTransferOrder, deleteBankTransferOrder, editBankTransferOrder, getAllBankTransferOrders, getBankTransferOrder, addBankTransferOrderPdf, editBankTransferOrderStatus } from "../request/bankTransferOrderRequest";



export const fetchAllBankTransferOrders = createAsyncThunk('import/fetchAllBankTransferOrders', async (data) => {

    const response = await getAllBankTransferOrders(data)
    return response.data.items
})

export const searchBankTransferOrder = createAsyncThunk('import/searchBankTransferOrder', async (payload) => {

    const response = await getBankTransferOrder(payload)
    return response.data
})

export const newBankTransferOrder = createAsyncThunk('import/newBankTransferOrder', async (payload, thunkAPI) => {

    try {
        const response = await addBankTransferOrder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateBankTransferOrder = createAsyncThunk('import/updateBankTransferOrder', async (payload, thunkAPI) => {

    try {
        const response = await editBankTransferOrder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeBankTransferOrder = createAsyncThunk('import/removeBankTransferOrder', async (payload) => {
    const response = await deleteBankTransferOrder(payload)
    return {}
})

export const updateBankTransferOrderStatus = createAsyncThunk('import/updateBankTransferOrderStatus', async (payload, thunkAPI) => {

    try {
        const response = await editBankTransferOrderStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadBankTransferOrderPdf = createAsyncThunk('import/uploadBankTransferOrderPdf', async (payload, thunkAPI) => {

    try {
        const response = await addBankTransferOrderPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}


//Reducers
const bankTransferOrderSlice = createSlice({
    name: "bankTransferOrder",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Forwarder  Quotation :
        builder
            .addCase(fetchAllBankTransferOrders.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllBankTransferOrders.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllBankTransferOrders.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Forwarder  Quotation :
        builder
            .addCase(searchBankTransferOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchBankTransferOrder.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchBankTransferOrder.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Forwarder  Quotation :
        builder
            .addCase(newBankTransferOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newBankTransferOrder.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newBankTransferOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Forwarder  Quotation :
        builder
            .addCase(updateBankTransferOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBankTransferOrder.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBankTransferOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Forwarder  Quotation :
        builder
            .addCase(removeBankTransferOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeBankTransferOrder.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeBankTransferOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Forwarder  Quotation Status :
        builder
            .addCase(updateBankTransferOrderStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateBankTransferOrderStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateBankTransferOrderStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })


        //Upload Forwarder  Quotation Pdf :
        builder
            .addCase(uploadBankTransferOrderPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadBankTransferOrderPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadBankTransferOrderPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default bankTransferOrderSlice.reducer;