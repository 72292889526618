import axios from "axios";
import config from "../../_config.json"

const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}

export const getAllSalesCustomersBalance = async () => {

    let token = localStorage.getItem('token')


    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance`
    })
};

export const getSalesCustomerBalance = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance/${payload}`
    })
};

export const addSalesCustomerBalance = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance`,
        data: payload
    })

};

export const editSalesCustomerBalance = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance/${payload.id}`,
        data: { ...payload.data }
    })

};

export const deleteSalesCustomerBalance = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance/${payload}`,
    })

};

export const createSalesCustomerBalance = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomerBalance/save`,
        data: payload
    })

};
