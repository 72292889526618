// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllForwarders, addForwarder, getForwarder, editForwarder, deleteForwarder } from "../request/forwarderRequest";

export const fetchAllForwarders = createAsyncThunk('import/fetchAllForwarders', async () => {

    const response = await getAllForwarders()
    return response.data.items
})

export const searchForwarder = createAsyncThunk('import/searchForwarder', async (payload) => {

    const response = await getForwarder(payload)
    return response.data
})

export const newForwarder = createAsyncThunk('import/newForwarder', async (payload, thunkAPI) => {

    try {
        const response = await addForwarder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateForwarder = createAsyncThunk('import/updateForwarder', async (payload, thunkAPI) => {

    try {
        const response = await editForwarder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeForwarder = createAsyncThunk('import/removeForwarder', async (data) => {

    const response = await deleteForwarder(data)
    return {}
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const forwarderSlice = createSlice({
    name: "forwarder",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Forwarder :

        builder
            .addCase(fetchAllForwarders.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllForwarders.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllForwarders.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Forwarder :

        builder
            .addCase(searchForwarder.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchForwarder.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchForwarder.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Add Forwarder:

        builder
            .addCase(newForwarder.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newForwarder.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newForwarder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })
        //Edit Forwarder:

        builder
            .addCase(updateForwarder.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateForwarder.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateForwarder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete Forwarder:

        builder
            .addCase(removeForwarder.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeForwarder.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeForwarder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default forwarderSlice.reducer;