//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import { getUsers, addUser, editUser, deleteUser, blockDeblockUser, getMe, editPassword, editUserPermission, getUserPermission } from "../request/userRequest";
import { object } from "yup";

export const fetchMe = createAsyncThunk('user/fetchMe', async (_, thunkAPI) => {


    const response = await getMe()

    return response.data

})

export const fetchUsers = createAsyncThunk('user/fetchUsers', async (_, thunkAPI) => {


    const response = await getUsers()

    return response.data.items

})

export const fetchUserPermissions = createAsyncThunk('user/fetchUserPermissions', async (data, thunkAPI) => {


    const response = await getUserPermission(data)

    return response.data.items

})

export const newUser = createAsyncThunk('user/newUser', async (data, thunkAPI) => {
   
    try {
        const response = await addUser({ Name: data.name, FirstName: data.firstName, Email: data.email ,Caisse : data.caisse })
        return thunkAPI.fulfillWithValue({})
    } catch (e) {
        return thunkAPI.rejectWithValue({});

    }
})

export const updateUser = createAsyncThunk('user/updateUser', async (data, thunkAPI) => {
console.log(data)
    try {

        const response = await editUserPermission(data)

        return thunkAPI.fulfillWithValue({})


    } catch (e) {

        return thunkAPI.rejectWithValue({ e });

    }
})

export const removeUser = createAsyncThunk('user/removeUser', async (data, thunkAPI) => {


    const response = await deleteUser(data)

    return {}

})

export const cancelInvite = createAsyncThunk('user/cancelInvite', async (data, thunkAPI) => {


    const response = await deleteUser(data)

    return {}

})

export const blockUser = createAsyncThunk('user/blockUser', async (data, thunkAPI) => {
    try {

        const response = await blockDeblockUser(data)

        return thunkAPI.fulfillWithValue()

    } catch (e) {

        return thunkAPI.rejectWithValue({ error: err.response.data });

    }
})


export const sendInvite = createAsyncThunk('user/resend', async (data) => {

    const response = await addUser({ Name: data.lastName, FirstName: data.firstName, Email: data.email, resend: true })

    return {}

})


export const unblockUser = createAsyncThunk('user/unblockUser', async (data, thunkAPI) => {
    try {

        const response = await blockDeblockUser(data)

        return thunkAPI.fulfillWithValue()

    } catch (e) {

        return thunkAPI.rejectWithValue({ error: err.response.data });

    }
})


export const updatePassword = createAsyncThunk('user/updatePassword', async (data, thunkAPI) => {

    try {

        const response = await editPassword(data)

        return thunkAPI.fulfillWithValue()

    } catch (e) {

        return thunkAPI.rejectWithValue({ error: "somenthing went wrong" });

    }
})



const initialState = {
    info: [],
    curentUser: {} ,
    permissions: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const userSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Me :
        builder
            .addCase(fetchMe.pending, (state, action) => {
                return { ...state, status: "loading", type: "getOne" }
            })
            .addCase(fetchMe.fulfilled, (state, action) => {
                return { ...state, curentUser: action.payload, type: "getOne", status: "succeded" }
            })
            .addCase(fetchMe.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "getOne", status: "failed" }
            })


        //Fetch Users :
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                return { ...state, status: "loading", type: "get" }
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })

        //Fetch User Permissions :
        builder
            .addCase(fetchUserPermissions.pending, (state, action) => {
                return { ...state, status: "loading", type: "getPermissions" }
            })
            .addCase(fetchUserPermissions.fulfilled, (state, action) => {
                return { ...state, permissions: action.payload, type: "getPermissions", status: "succeded" }
            })
            .addCase(fetchUserPermissions.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "getPermissions", status: "failed" }
            })


        //New User :
        builder
            .addCase(newUser.pending, (state, action) => {
                return { ...state, status: "loading", type: "post" }
            })
            .addCase(newUser.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newUser.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error, type: 'post', status: "failed" }
            })


        //Update User :
        builder
            .addCase(updateUser.pending, (state, action) => {
                return { ...state, status: "loading", type: "put" }
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateUser.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error, type: 'put', status: "failed" }
            })



        //Remove User :
        builder
            .addCase(removeUser.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete" }
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })

        builder
            .addCase(cancelInvite.pending, (state, action) => {
                return { ...state, status: "loading", type: "cancel" }
            })
            .addCase(cancelInvite.fulfilled, (state, action) => {
                return { ...state, type: "cancel", status: "succeded" }
            })


        //Block User : 

        builder
            .addCase(blockUser.fulfilled, (state, action) => {
                return { ...state, type: "block", status: "succeded" }
            })
            .addCase(blockUser.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error.errors, type: 'block', status: "failed" }
            })

        //Unblock User : 

        builder
            .addCase(unblockUser.fulfilled, (state, action) => {
                return { ...state, type: "unblock", status: "succeded" }
            })
            .addCase(unblockUser.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error.errors, type: 'unblock', status: "failed" }
            })


        //Send Invite : 

        builder
            .addCase(sendInvite.fulfilled, (state, action) => {
                return { ...state, type: "resend", status: "succeded" }
            })
            .addCase(sendInvite.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error.errors, type: 'resend', status: "failed" }
            })



        //Update User's Password :

        builder
            .addCase(updatePassword.pending, (state, action) => {
                return { ...state, status: "loading", type: "password" }
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                return { ...state, type: "password", status: "succeded" }
            })
            .addCase(updatePassword.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error, type: 'password', status: "failed" }
            })



    }
})

export default userSlice.reducer;