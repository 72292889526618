//@ts-nocheck
import axios from "axios";
import config from "../../_config.json";

// Helper function to get headers with token and tenant ID
const getHeaders = (token) => {
  return {
    'Authorization': 'Bearer ' + token,
    'x-tenant-id': localStorage.getItem('tenantId'),
    'Content-Type': 'application/json'
  };
};

// GET all Point of Sales
export const getAllPointOfSales = async () => {
  let token = localStorage.getItem('token');

  return await axios.request({
    method: "get",
    headers: getHeaders(token),
    withCredentials: true,
    url: `${config.apiUrl}/PointOfSales`
  });
};

// POST to create a new Point of Sale
export const addPointOfSale = async (payload) => {
  let token = localStorage.getItem('token');

  return await axios.request({
    method: "post",
    headers: getHeaders(token),
    withCredentials: true,
    url: `${config.apiUrl}/PointOfSales`,
    data: payload
  });
};

// PUT to update a Point of Sale by ID
export const editPointOfSale = async (payload) => {
  let token = localStorage.getItem('token');

  return await axios.request({
    method: "put",
    headers: getHeaders(token),
    withCredentials: true,
    url: `${config.apiUrl}/PointOfSales/${payload.id}`,
    data: payload
  });
};

// GET a specific Point of Sale by ID
export const getPointOfSaleItem = async (id) => {
  let token = localStorage.getItem('token');

  return await axios.request({
    method: "get",
    headers: getHeaders(token),
    withCredentials: true,
    url: `${config.apiUrl}/PointOfSales/${id}`
  });
};

// DELETE a Point of Sale by ID
export const deletePointOfSale = async (id) => {
  let token = localStorage.getItem('token');

  return await axios.request({
    method: "delete",
    headers: getHeaders(token),
    withCredentials: true,
    url: `${config.apiUrl}/PointOfSales/${id}`
  });
};
