// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addDossier, deleteDossier, editDossier, editDossierProgressstatus, editDossierStatus, getAllDossiers, getDossier } from "../request/dossierRequest";

export const fetchAllDossiers = createAsyncThunk('import/fetchAllDossiers', async () => {

    const response = await getAllDossiers()
    return response.data.items
})

export const searchDossier = createAsyncThunk('import/searchDossier', async (payload) => {

    const response = await getDossier(payload)
    return response.data
})

export const newDossier = createAsyncThunk('import/newDossier', async (payload, thunkAPI) => {

    try {
        const response = await addDossier(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateDossier = createAsyncThunk('import/updateDossier', async (payload, thunkAPI) => {

    try {
        const response = await editDossier(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateDossierStatus = createAsyncThunk('po/updateDossierStatus', async (payload, thunkAPI) => {

    try {
        const response = await editDossierStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateDossierProgressstatus = createAsyncThunk('po/updateDossierProgressstatus', async (payload, thunkAPI) => {

    try {
      
        const response = await editDossierProgressstatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeDossier = createAsyncThunk('import/removeDossier', async (data) => {

    const response = await deleteDossier(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const dossierSlice = createSlice({
    name: "dossier",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Dossier :
        builder
            .addCase(fetchAllDossiers.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllDossiers.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllDossiers.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Dossier :
        builder
            .addCase(searchDossier.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchDossier.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchDossier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Dossier :
        builder
            .addCase(newDossier.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newDossier.fulfilled, (state, action) => {
                return { ...state, info: [action.payload, ...state.info], type: "post", status: "succeded" }
            })
            .addCase(newDossier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Dossier:
        builder
            .addCase(updateDossier.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateDossier.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateDossier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete Dossier:
        builder
            .addCase(removeDossier.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeDossier.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeDossier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Dossier Status :
        builder
            .addCase(updateDossierStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateDossierStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateDossierStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })
    }
})

export default dossierSlice.reducer;