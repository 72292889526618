// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCustomerBalance } from "../request/salesCustomerBalanceRequest";

export const searchCustomerBalance = createAsyncThunk('sales/customer_balance', async (payload) => {

    const response = await getCustomerBalance(payload)
    return response.data.items
})

const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const customerSlice = createSlice({
    name: "customer",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Seach  Bank :
        builder
            .addCase(searchCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchCustomerBalance.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "search", status: "succeded" }
            })
            .addCase(searchCustomerBalance.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })
    }
})

export default customerSlice.reducer;