//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompany, addCompany, editCompany } from "../request/companyRequest";

export const fetchCompany = createAsyncThunk('company/fetchCompany', async (data, thunkAPI) => {

    const response = await getCompany()
    return response.data
})

export const newCompany = createAsyncThunk('company/newCompany', async (data, thunkAPI) => {

    try {
        const response = await addCompany(data)
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue({ e });
    }
})

export const UpdateCompany = createAsyncThunk('company/UpdateCompany', async (data, thunkAPI) => {

    try {
        const response = await editCompany(data)
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue({ e });
    }
})

const initialState = {
    info: {
        id: null,
        name: '',
        companyId: '',
        phoneNumber: null,
        address: '',
        logo: null,
        footer: '',

    },
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const companySlice = createSlice({
    name: "company",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchCompany.pending, (state, action) => {
                return { ...state, status: "loading", type: "get" }
            })
            .addCase(fetchCompany.fulfilled, (state, action) => {
                return { ...state, info: { ...action.payload }, type: "get", status: "succeded" }
            })
            .addCase(fetchCompany.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Create Company :
        builder
            .addCase(newCompany.pending, (state, action) => {
                return { ...state, status: "loading", type: "post" }
            })
            .addCase(newCompany.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newCompany.rejected, (state, action) => {
                return { ...state, type: "post", status: "failed" }
            })

        //Edit Company :
        builder
            .addCase(UpdateCompany.pending, (state, action) => {
                return { ...state, status: "loading", type: "put" }
            })
            .addCase(UpdateCompany.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(UpdateCompany.rejected, (state, action) => {
                return { ...state, type: "put", status: "failed" }
            })
    }
})

export default companySlice.reducer;