// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addPo, getAllPos, editPo, editPoStatus, getValidatedPos, getPoByRef, deletePo, addComment, deleteComment, editComment } from "../request/poRequest";

export const fetchAllPos = createAsyncThunk('po/fetchAllPos', async () => {

    const response = await getAllPos()
    return response.data.items
})

export const fetchValidatedPos = createAsyncThunk('po/fetchValidatedPos', async () => {

    const response = await getValidatedPos()
    return response.data.items
})

export const searchPoByRef = createAsyncThunk('po/searchPoByRef', async (payload) => {

    const response = await getPoByRef(payload)
    return response.data
})

export const newPo = createAsyncThunk('po/newPo', async (payload, thunkAPI) => {

    try {
        const response = await addPo(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updatePo = createAsyncThunk('po/updatePo', async (payload, thunkAPI) => {

    try {
        const response = await editPo(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updatePoStatus = createAsyncThunk('po/updatePoStatus', async (payload, thunkAPI) => {

    try {
        const response = await editPoStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removePo = createAsyncThunk('po/removePo', async (payload) => {
    const response = await deletePo(payload)
    return {}
})


const initialState = {
    info: [],
    item: {},
    status: 'idle',
    type: null,
    errors: {},
    Comment: {}
}

//Reducers
const poSlice = createSlice({
    name: "po",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All POs :

        builder
            .addCase(fetchAllPos.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllPos.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllPos.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Fetch Validated POs :

        builder
            .addCase(fetchValidatedPos.pending, (state, action) => {
                return { ...state, status: "loading", type: "get_validated", errors: {} }
            })
            .addCase(fetchValidatedPos.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get_validated", status: "succeded" }
            })
            .addCase(fetchValidatedPos.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get_validated", status: "failed" }
            })

        // Search  PO by Ref:
        builder
            .addCase(searchPoByRef.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchPoByRef.fulfilled, (state, action) => {
                return { ...state, item: { ...action.payload }, type: "search", status: "succeded" }
            })
            .addCase(searchPoByRef.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })


        //New PO :
        builder
            .addCase(newPo.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newPo.fulfilled, (state, action) => {
                return {
                    ...state, type: "post", status: "succeded"
                }
            })
            .addCase(newPo.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update PO :
        builder
            .addCase(updatePo.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updatePo.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updatePo.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Update PO Status :
        builder
            .addCase(updatePoStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updatePoStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updatePoStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Delete PO :
        builder
            .addCase(removePo.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removePo.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removePo.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

       
    }
})

export default poSlice.reducer;