// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTenant, editTenant, deleteTenant, createTenant } from "../request/tenantRequest";

export const fetchTenant = createAsyncThunk('tenant/fetchTenant', async () => {

    const response = await getTenant()

    return response.data.items[0]
})


export const newTenant = createAsyncThunk('tenant/newTenant', async (payload, thunkAPI) => {
    try {
        const response = await createTenant(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})

export const updateTenant = createAsyncThunk('tenant/editTenant', async (payload) => {
    const response = await editTenant(payload)

    return { id: payload.id, name: payload.data.name }
})


export const removeTenant = createAsyncThunk('tenant/deleteTenant', async (payload) => {

    const response = await deleteTenant(payload)
    return { id: null, name: '' }
})



const initialState = {
    info: { id: null, name: '' },
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const tenantSlice = createSlice({
    name: "tenant",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Tenant :
        builder

            .addCase(fetchTenant.fulfilled, (state, action) => {
                if (action.payload != null) localStorage.setItem('tenantId', action.payload.id)
                return { ...state, info: { ...action.payload }, type: 'get', status: "succeded" }
            })

        //New Tenant
        builder
            .addCase(newTenant.pending, (state, action) => {
                return { ...state, status: 'loading', type: 'post' }
            })
            .addCase(newTenant.fulfilled, (state, action) => {

                return { ...state, info: action.payload.name, type: 'post', status: "succeded" }
            })
            .addCase(newTenant.rejected, (state, { payload }) => {

                return { ...state, errors: payload.error.errors, type: 'post', status: "failed" }
            })


        //Update Tenant
        builder
            .addCase(updateTenant.pending, (state, action) => {
                return { ...state, status: 'loading', type: 'put' }
            })
            .addCase(updateTenant.fulfilled, (state, action) => {

                return { ...state, info: action.payload, type: 'put', status: "succeded" }
            })


        //Delete Tenant
        builder
            .addCase(removeTenant.pending, (state, action) => {
                return { ...state, status: 'loading', type: 'delete' }
            })
            .addCase(removeTenant.fulfilled, (state, action) => {
                localStorage.removeItem('tenantId')
                return { ...state, info: action.payload, type: 'delete', status: "succeded" }
            })
    }



});

export default tenantSlice.reducer;
