// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addSimulation, deleteSimulation, editSimulation, getAllSimulations, getSimulation, getSimulationCiBons } from "../request/simulationRequest";


export const fetchAllSimulations = createAsyncThunk('import/fetchAllSimulations', async () => {

    const response = await getAllSimulations()
    return response.data.items
})

export const searchSimultion = createAsyncThunk('import/searchSimultion', async (payload) => {

    const response = await getSimulation(payload)
    return response.data
})

export const searchSimultionCIBons = createAsyncThunk('import/searchSimultionCIBons', async () => {

    const response = await getSimulationCiBons()
    return response.data.items
})

export const newSimulation = createAsyncThunk('import/newSimulation', async (payload, thunkAPI) => {

    try {
        const response = await addSimulation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSimulation = createAsyncThunk('import/updateSimulation', async (payload, thunkAPI) => {

    try {
        const response = await editSimulation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeSimulation = createAsyncThunk('import/removeSimulation', async (payload, thunkAPI) => {

    try {
        const response = await deleteSimulation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    bons: [],
    status: 'idle',
    type: null,
    errors: {}
}


//Reducers
const simulationSlice = createSlice({
    name: "simulation",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {
        //Seach Simulation :
        builder
            .addCase(searchSimultion.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSimultion.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSimultion.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Fetch All ProformalInvoice :
        builder
            .addCase(fetchAllSimulations.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSimulations.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllSimulations.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Fetch Simulation bon CI :
        builder
            .addCase(searchSimultionCIBons.pending, (state, action) => {
                return { ...state, status: "loading", type: "getBon", errors: {} }
            })
            .addCase(searchSimultionCIBons.fulfilled, (state, action) => {
                return { ...state, bons: [...action.payload], type: "getBon", status: "succeded" }
            })
            .addCase(searchSimultionCIBons.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "getBon", status: "failed" }
            })

        //New simulation :
        builder
            .addCase(newSimulation.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSimulation.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSimulation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit ProformalInvoice:
        builder
            .addCase(updateSimulation.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSimulation.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSimulation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete Simulation:
        builder
            .addCase(removeSimulation.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSimulation.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSimulation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })
    }
})

export default simulationSlice.reducer;