// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addPaymentTicket, addPaymentTicketPdf, editPaymentTicketStatus, deletePaymentTicket, editPaymentTicket, getAllPaymentTickets, getPaymentTicket } from "../request/paymantTicketRequest";

export const fetchAllPaymentTickets = createAsyncThunk('import/fetchAllPaymentTickets', async () => {

    const response = await getAllPaymentTickets()
    return response.data.items
})



export const searchPaymentTicket = createAsyncThunk('import/searchPaymentTicket', async (payload) => {

    const response = await getPaymentTicket(payload)
    return response.data
})

export const newPaymentTicket = createAsyncThunk('import/newPaymentTicket', async (payload, thunkAPI) => {

    try {
        const response = await addPaymentTicket(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updatePaymentTicket = createAsyncThunk('import/updatePaymentTicket', async (payload, thunkAPI) => {

    try {
        const response = await editPaymentTicket(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removePaymentTicket = createAsyncThunk('import/removePaymentTicket', async (payload) => {
    const response = await deletePaymentTicket(payload)
    return {}
})

export const updatePaymentTicketStatus = createAsyncThunk('import/updatePaymentTicketStatus', async (payload, thunkAPI) => {

    try {
        const response = await editPaymentTicketStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadPaymentTicketPdf = createAsyncThunk('import/uploadPaymentTicketPdf', async (payload, thunkAPI) => {

    try {
        const response = await addPaymentTicketPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const paymentTicket = createSlice({
    name: "bl",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Payment Ticket :
        builder
            .addCase(fetchAllPaymentTickets.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllPaymentTickets.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllPaymentTickets.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Payment Ticket :
        builder
            .addCase(searchPaymentTicket.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchPaymentTicket.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchPaymentTicket.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Payment Ticket :
        builder
            .addCase(newPaymentTicket.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newPaymentTicket.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newPaymentTicket.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Payment Ticket:
        builder
            .addCase(updatePaymentTicket.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updatePaymentTicket.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updatePaymentTicket.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Payment Ticket:
        builder
            .addCase(removePaymentTicket.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removePaymentTicket.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removePaymentTicket.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Payment Ticket Status :
        builder
            .addCase(updatePaymentTicketStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updatePaymentTicketStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updatePaymentTicketStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Upload Payment Ticket Pdf :
        builder
            .addCase(uploadPaymentTicketPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadPaymentTicketPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadPaymentTicketPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default paymentTicket.reducer;