// @ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}



export const getAllBanks = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank`
    })
};

export const getBank = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank/${payload}`
    })
};

export const getDefaultBank = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank/Default`
    })
};

export const addBank = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank`,
        data: payload
    })
};

export const editBank = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank/${payload.id}`,
        data: payload.data
    })
};

export const deleteBank = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank/${payload}`,
    })
};

export const addBankPdf = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Bank/${payload.id}/pdf`,
        data: payload.data
    })
};
