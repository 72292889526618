// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addForwarderRequest, deleteForwarderRequest, editForwarderRequest, getAllForwarderRequests, getForwarderRequest, addForwarderRequestPdf, editForwarderRequestStatus } from "../request/forwarderRequestRequest";



export const fetchAllForwarderRequests = createAsyncThunk('import/fetchAllForwarderRequests', async (data) => {

    const response = await getAllForwarderRequests(data)
    return response.data.items
})

export const searchForwarderRequest = createAsyncThunk('import/searchForwarderRequest', async (payload) => {

    const response = await getForwarderRequest(payload)
    return response.data
})

export const newForwarderRequest = createAsyncThunk('import/newForwarderRequest', async (payload, thunkAPI) => {

    try {
        const response = await addForwarderRequest(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateForwarderRequest = createAsyncThunk('import/updateForwarderRequest', async (payload, thunkAPI) => {

    try {
        const response = await editForwarderRequest(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeForwarderRequest = createAsyncThunk('import/removeForwarderRequest', async (payload) => {
    const response = await deleteForwarderRequest(payload)
    return {}
})

export const updateForwarderRequestStatus = createAsyncThunk('import/updateForwarderRequestStatus', async (payload, thunkAPI) => {

    try {
        const response = await editForwarderRequestStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadForwarderRequestPdf = createAsyncThunk('import/uploadForwarderRequestPdf', async (payload, thunkAPI) => {

    try {
        const response = await addForwarderRequestPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const forwarderRequestSlice = createSlice({
    name: "forwarderRequestSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Forwarder Request Quotation :
        builder
            .addCase(fetchAllForwarderRequests.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllForwarderRequests.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllForwarderRequests.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Forwarder Request Quotation :
        builder
            .addCase(searchForwarderRequest.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchForwarderRequest.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchForwarderRequest.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Forwarder Request Quotation :
        builder
            .addCase(newForwarderRequest.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newForwarderRequest.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newForwarderRequest.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Forwarder Request Quotation:
        builder
            .addCase(updateForwarderRequest.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateForwarderRequest.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateForwarderRequest.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Forwarder Request Quotation:
        builder
            .addCase(removeForwarderRequest.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeForwarderRequest.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeForwarderRequest.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload  Forwarder Request Quotation Pdf :
        builder
            .addCase(uploadForwarderRequestPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadForwarderRequestPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadForwarderRequestPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })

        //Update  Forwarder Request Quotation Status :
        builder
            .addCase(updateForwarderRequestStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateForwarderRequestStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateForwarderRequestStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

    }
})

export default forwarderRequestSlice.reducer;