// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addSupplierInvoice, deleteSupplierInvoice, editSupplierInvoice, editSupplierInvoiceStatus, getAllSupplierInvoice, getSupplierInvoice } from "../request/supplierInvoiceRequest";

export const fetchAllSupplierInvoice = createAsyncThunk('invoice/fetchAllSupplierInvoice', async () => {

    const response = await getAllSupplierInvoice()
    return response.data.items
})

export const searchSupplierInvoice = createAsyncThunk('invoice/searchSupplierInvoice', async (payload) => {

    const response = await getSupplierInvoice(payload)
    return response.data.items
})

export const newSupplierInvoice = createAsyncThunk('invoice/newSupplierInvoice', async (payload, thunkAPI) => {

    try {
        const response = await addSupplierInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSupplierInvoice = createAsyncThunk('invoice/updateSupplierInvoice', async (payload, thunkAPI) => {

    try {
        const response = await editSupplierInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSupplierInvoiceStatus = createAsyncThunk('invoice/updateSupplierInvoiceStatus', async (payload, thunkAPI) => {

    try {
        const response = await editSupplierInvoiceStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeSupplierInvoice = createAsyncThunk('invoice/removeSupplierInvoice', async (payload, thunkAPI) => {

    const response = await deleteSupplierInvoice(payload)
    return {}
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const supplierInvoiceSlice = createSlice({
    name: "balance",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Supplier Invoice:

        builder
            .addCase(fetchAllSupplierInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSupplierInvoice.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllSupplierInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Search Supplier Payment:
        builder
            .addCase(searchSupplierInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSupplierInvoice.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSupplierInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New  Supplier Payment :
        builder
            .addCase(newSupplierInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSupplierInvoice.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSupplierInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update  Supplier Invoice :
        builder
            .addCase(updateSupplierInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSupplierInvoice.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSupplierInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Update  Supplier Invoice Status :
        builder
            .addCase(updateSupplierInvoiceStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateSupplierInvoiceStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateSupplierInvoiceStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        builder
            .addCase(removeSupplierInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSupplierInvoice.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSupplierInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })
    }
})

export default supplierInvoiceSlice.reducer;