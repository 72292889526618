// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { 
  addPointOfSale, 
  deletePointOfSale, 
  editPointOfSale, 
  getAllPointOfSales, 
  getPointOfSaleItem 
} from "../request/PointOfSalesRequest";

// Async Thunks
export const fetchAllPointOfSales = createAsyncThunk('pointOfSales/fetchAllPointOfSales', async () => {
  const response = await getAllPointOfSales();

  return response.data.items;
});

export const searchPointOfSaleItem = createAsyncThunk('pointOfSales/searchPointOfSaleItem', async (payload, thunkAPI) => {
  try {
    const response = await getPointOfSaleItem(payload);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.response.data });
  }
});

export const newPointOfSale = createAsyncThunk('pointOfSales/newPointOfSale', async (payload, thunkAPI) => {
  try {
    const response = await addPointOfSale(payload);
    return thunkAPI.fulfillWithValue(payload);
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.response.data });
  }
});

export const updatePointOfSale = createAsyncThunk('pointOfSales/updatePointOfSale', async (payload, thunkAPI) => {

  try {
    const response = await editPointOfSale(payload);
    return thunkAPI.fulfillWithValue(payload);
  } catch (err) {
    return thunkAPI.rejectWithValue({ error: err.response.data });
  }
});

export const removePointOfSale = createAsyncThunk('pointOfSales/removePointOfSale', async (payload) => {
  const response = await deletePointOfSale(payload);
  return {};
});

// Initial State
const initialState = {
  info: [],
  item: {},
  status: 'idle',
  type: null,
  errors: {}
};

// Slice
const pointOfSalesSlice = createSlice({
  name: "pointOfSales",
  initialState: initialState,
  reducers: {},
  
  extraReducers(builder) {
    // Fetch All Point of Sales
    builder
      .addCase(fetchAllPointOfSales.pending, (state) => {
        return { ...state, status: "loading", type: "get", errors: {} };
      })
      .addCase(fetchAllPointOfSales.fulfilled, (state, action) => {
        return { ...state, info: [...action.payload], type: "get", status: "succeeded" };
      })
      .addCase(fetchAllPointOfSales.rejected, (state, action) => {
        return { ...state, errors: action.payload, type: "get", status: "failed" };
      });

    // Search Point of Sale Item
    builder
      .addCase(searchPointOfSaleItem.pending, (state) => {
        return { ...state, status: "loading", type: "search", errors: {} };
      })
      .addCase(searchPointOfSaleItem.fulfilled, (state, action) => {
        return { ...state, item: { ...action.payload }, type: "search", status: "succeeded" };
      })
      .addCase(searchPointOfSaleItem.rejected, (state, action) => {
        return { ...state, errors: action.payload, type: "search", status: "failed" };
      });

    // New Point of Sale
    builder
      .addCase(newPointOfSale.pending, (state) => {
        return { ...state, status: "loading", type: "post", errors: {} };
      })
      .addCase(newPointOfSale.fulfilled, (state) => {
        return { ...state, type: "post", status: "succeeded" };
      })
      .addCase(newPointOfSale.rejected, (state, { payload }) => {
        return { ...state, errors: payload.error.errors, type: "post", status: "failed" };
      });

    // Update Point of Sale
    builder
      .addCase(updatePointOfSale.pending, (state) => {
        return { ...state, status: "loading", type: "put", errors: {} };
      })
      .addCase(updatePointOfSale.fulfilled, (state) => {
        return { ...state, type: "put", status: "succeeded" };
      })
      .addCase(updatePointOfSale.rejected, (state, { payload }) => {
        return { ...state, errors: payload.error.errors, type: "put", status: "failed" };
      });

    // Delete Point of Sale
    builder
      .addCase(removePointOfSale.pending, (state) => {
        return { ...state, status: "loading", type: "delete", errors: {} };
      })
      .addCase(removePointOfSale.fulfilled, (state) => {
        return { ...state, type: "delete", status: "succeeded" };
      })
      .addCase(removePointOfSale.rejected, (state, { payload }) => {
        return { ...state, errors: payload.error.errors, type: "delete", status: "failed" };
      });
  }
});

export default pointOfSalesSlice.reducer;
