import axios from "axios";
import config from "../../_config.json"

const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}

export const getAllSalesOrder = async () => {

    let token = localStorage.getItem('token')


    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder`
    })
};

export const getAllSalesOrderBon = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/orders/${payload.customerId}/${payload.orderId}`
    })
};
export const getSalesOrderBonDeliverd = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/bon?libelle=${payload.product}`
    })
};

export const getSalesOrder = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/${payload}`
    })
};

export const getSalesOrderCalcules = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/attentedePaiement/${payload}`
    })
};

export const addSalesOrder = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder`,
        data: payload
    })

};


export const addQuickOrder = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/QuickOrder`,
        data: payload
    })

};


export const editSalesOrder = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/${payload.id}`,
        data: { ...payload.data }
    })

};

export const deleteSalesOrder = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/${payload}`,
    })

};


export const editSalesOrderStatus = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesOrder/${payload.id}/status`,
        data: { ...payload.data }
    })

};
