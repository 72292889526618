//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}

const getHeadersFile = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        "Accept": "*/*",
        'Content-Type': "multipart/form-data"
    }
}

export const getCompany = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/company`,
    })

};

export const addCompany = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeadersFile(token),
        withCredentials: true,
        url: `${config.apiUrl}/company`,
        data: payload
    })

};

export const editCompany = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeadersFile(token),
        withCredentials: true,
        url: `${config.apiUrl}/company/${payload.id}`,
        data: payload.data
    })

};