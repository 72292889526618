//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}



export const getAllPackingLists = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList`
    })
};

export const getPackingList = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList/${payload}`
    })
};

export const getPackingListBon = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList/${payload}/bons`
    })
};

export const addPackingList = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList`,
        data: payload
    })
};


export const editPackingList = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList/${payload.id}`,
        data: payload.data
    })
};

export const deletePackingList = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList/${payload}`,
    })
};


export const addPackingListPdf = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/packingList/${payload.id}/pdf`,
        data: payload.data
    })
};
