// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllTrackings, addTracking, deleteTracking, editTracking, getTracking, getTrackingbyPl } from "../request/trackRequest";

export const fetchAllTracks = createAsyncThunk('track/fetchAllTracks', async () => {

    const response = await getAllTrackings()
    return response.data.items
})

export const searchTrack = createAsyncThunk('track/searchTrack', async (payload) => {

    const response = await getTracking(payload)
    return response.data
})

export const searchTrackByPl = createAsyncThunk('track/searchTrackByPl', async (payload) => {

    const response = await getTrackingbyPl(payload)
    return response.data.items
})


export const newTrack = createAsyncThunk('track/newTrack', async (payload, thunkAPI) => {

    try {
        const response = await addTracking(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateTrack = createAsyncThunk('track/updateTrack', async (payload, thunkAPI) => {

    try {
        const response = await editTracking(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeTrack = createAsyncThunk('track/removeTrack', async (data) => {

    const response = await deleteTracking(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}


//Reducers
const trackSlice = createSlice({
    name: "Track",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Tracks :

        builder
            .addCase(fetchAllTracks.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllTracks.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllTracks.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Track :
        builder
            .addCase(searchTrack.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchTrack.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchTrack.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })

        //Seach Track :
        builder
            .addCase(searchTrackByPl.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchTrackByPl.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchTrackByPl.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })


        //New Track :
        builder
            .addCase(newTrack.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newTrack.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newTrack.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Track:

        builder
            .addCase(updateTrack.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateTrack.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateTrack.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete Track:
        builder
            .addCase(removeTrack.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeTrack.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeTrack.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default trackSlice.reducer;