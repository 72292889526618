//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}


export const getAllDueDate = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests`
    })
};

export const updateDueDateRequestStatus = async (id, payload) => {
    let token = localStorage.getItem('token');
    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests/${id}/status`,
        data: payload
    });
};

export const getDueDate = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests/${payload}`
    })
};

export const getDueDatebyTransaction = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests/${payload}`
    })
};

export const addDueDate = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests`,
        data: payload
    })
};

export const deleteDueDate = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/duedaterequests/${payload}`,
    })
};