// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllSupplierBalance } from "../request/supplierBalanceRequest";

export const fetchAllSupplierBalance = createAsyncThunk('purchase/fetchAllSupplierBalance', async () => {

    const response = await getAllSupplierBalance()
    return response.data.items
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const supplierBalanceSlice = createSlice({
    name: "balance",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Supplier Balance:

        builder
            .addCase(fetchAllSupplierBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSupplierBalance.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllSupplierBalance.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })
    }
})

export default supplierBalanceSlice.reducer;