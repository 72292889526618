// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addForwarderQuotation, deleteForwarderQuotation, editForwarderQuotation, getAllForwarderQuotations, getForwarderQuotation, addForwarderQuotationPdf, editForwarderQuotationStatus } from "../request/forwarderQuotationRequest";



export const fetchAllForwarderQuotations = createAsyncThunk('import/fetchAllForwarderQuotations', async (data) => {

    const response = await getAllForwarderQuotations(data)
    return response.data.items
})

export const searchForwarderQuotation = createAsyncThunk('import/searchForwarderQuotation', async (payload) => {

    const response = await getForwarderQuotation(payload)
    return response.data
})

export const newForwarderQuotation = createAsyncThunk('import/newForwarderQuotation', async (payload, thunkAPI) => {

    try {
        const response = await addForwarderQuotation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateForwarderQuotation = createAsyncThunk('import/updateForwarderQuotation', async (payload, thunkAPI) => {

    try {
        const response = await editForwarderQuotation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeForwarderQuotation = createAsyncThunk('import/removeForwarderQuotation', async (payload) => {
    const response = await deleteForwarderQuotation(payload)
    return {}
})

export const updateForwarderQuotationStatus = createAsyncThunk('import/updateForwarderQuotationStatus', async (payload, thunkAPI) => {

    try {
        const response = await editForwarderQuotationStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadForwarderQuotationPdf = createAsyncThunk('import/uploadForwarderQuotationPdf', async (payload, thunkAPI) => {

    try {
        const response = await addForwarderQuotationPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}


//Reducers
const forwarderQuotationSlice = createSlice({
    name: "forwarderQuotationSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Forwarder  Quotation :
        builder
            .addCase(fetchAllForwarderQuotations.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllForwarderQuotations.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllForwarderQuotations.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Forwarder  Quotation :
        builder
            .addCase(searchForwarderQuotation.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchForwarderQuotation.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchForwarderQuotation.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Forwarder  Quotation :
        builder
            .addCase(newForwarderQuotation.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newForwarderQuotation.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newForwarderQuotation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Forwarder  Quotation :
        builder
            .addCase(updateForwarderQuotation.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateForwarderQuotation.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateForwarderQuotation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Forwarder  Quotation :
        builder
            .addCase(removeForwarderQuotation.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeForwarderQuotation.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeForwarderQuotation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Forwarder  Quotation Status :
        builder
            .addCase(updateForwarderQuotationStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateForwarderQuotationStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateForwarderQuotationStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })


        //Upload Forwarder  Quotation Pdf :
        builder
            .addCase(uploadForwarderQuotationPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadForwarderQuotationPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadForwarderQuotationPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default forwarderQuotationSlice.reducer;