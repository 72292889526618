// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addPackingList, getAllPackingLists, getPackingList, editPackingList, deletePackingList, addPackingListPdf, getPackingListBon } from "../request/packingRequest";

export const fetchAllPackingLists = createAsyncThunk('import/fetchAllPackingLists', async () => {

    const response = await getAllPackingLists()
    return response.data.items
})

export const searchPackingList = createAsyncThunk('import/searchPackingList', async (payload) => {

    const response = await getPackingList(payload)
    return response.data
})

export const fetchPackingListBon = createAsyncThunk('import/fetchPackingListBon', async (payload) => {

    const response = await getPackingListBon(payload)
    return response.data.items
})


export const newPackingList = createAsyncThunk('import/newPackingList', async (payload, thunkAPI) => {

    try {
        const response = await addPackingList(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updatePackingList = createAsyncThunk('import/updatePackingList', async (payload, thunkAPI) => {

    try {
        const response = await editPackingList(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removePackingList = createAsyncThunk('import/removePackingList', async (payload) => {
    const response = await deletePackingList(payload)
    return {}
})


export const uploadPackingListPdf = createAsyncThunk('import/uploadPackingListPdf', async (payload, thunkAPI) => {

    try {
        const response = await addPackingListPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const packingListSlice = createSlice({
    name: "packingList",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Packing List :
        builder
            .addCase(fetchAllPackingLists.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllPackingLists.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllPackingLists.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Packing List :
        builder
            .addCase(searchPackingList.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchPackingList.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchPackingList.rejected, (state, action) => {
                return { ...state, info: [], errors: action.payload, type: "search", status: "failed" }
            })

        //Fetch  Proforma Invoice Bon :
        builder
            .addCase(fetchPackingListBon.pending, (state, action) => {
                return { ...state, status: "loading", type: "get_bon", errors: {} }
            })
            .addCase(fetchPackingListBon.fulfilled, (state, action) => {
                return { ...state, bon: [...action.payload], type: "get_bon", status: "succeded" }
            })
            .addCase(fetchPackingListBon.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get_bon", status: "failed" }
            })

        //New Packing List :
        builder
            .addCase(newPackingList.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newPackingList.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newPackingList.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Packing List:
        builder
            .addCase(updatePackingList.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updatePackingList.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updatePackingList.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Packing List:
        builder
            .addCase(removePackingList.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removePackingList.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removePackingList.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Commercial Invoice Pdf :
        builder
            .addCase(uploadPackingListPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadPackingListPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadPackingListPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default packingListSlice.reducer;