// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addDum, addDumPdf, deleteDum, editDum, getAllDums, getDum } from "../request/dumRequest";

export const fetchAllDums = createAsyncThunk('import/fetchAllDums', async () => {

    const response = await getAllDums()
    return response.data.items
})



export const searchDum = createAsyncThunk('import/searchDum', async (payload) => {

    const response = await getDum(payload)
    return response.data
})

export const newDum = createAsyncThunk('import/newDum', async (payload, thunkAPI) => {

    try {
        const response = await addDum(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateDum = createAsyncThunk('import/updateDum', async (payload, thunkAPI) => {

    try {
        const response = await editDum(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeDum = createAsyncThunk('import/removeDum', async (payload) => {
    const response = await deleteDum(payload)
    return {}
})


export const uploadDumPdf = createAsyncThunk('import/uploadDumPdf', async (payload, thunkAPI) => {

    try {
        const response = await addDumPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    bon: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const dumSlice = createSlice({
    name: "dum",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Dum :
        builder
            .addCase(fetchAllDums.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllDums.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllDums.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Dum :
        builder
            .addCase(searchDum.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchDum.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchDum.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Dum :
        builder
            .addCase(newDum.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newDum.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newDum.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Dum:
        builder
            .addCase(updateDum.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateDum.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateDum.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Dum:
        builder
            .addCase(removeDum.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeDum.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeDum.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Dum Pdf :
        builder
            .addCase(uploadDumPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadDumPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadDumPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default dumSlice.reducer;