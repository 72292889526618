// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllTags, addTag, editTag, deleteTag } from "../request/tagRequest";

export const fetchAllTags = createAsyncThunk('tag/fetchAllTags', async () => {

    const response = await getAllTags()
    return response.data.items
})

export const newTag = createAsyncThunk('tag/newTag', async (payload, thunkAPI) => {

    try {
        const response = await addTag(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateTag = createAsyncThunk('tag/updateTag', async (payload, thunkAPI) => {

    try {
        const response = await editTag(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeTag = createAsyncThunk('tag/removeTag', async (payload, thunkAPI) => {

    try {
        const response = await deleteTag(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const tagSlice = createSlice({
    name: "tag",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Tags :

        builder
            .addCase(fetchAllTags.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllTags.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllTags.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Tag :
        builder
            .addCase(newTag.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newTag.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newTag.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Tag :
        builder
            .addCase(updateTag.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateTag.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateTag.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Remove Tag :
        builder
            .addCase(removeTag.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeTag.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeTag.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default tagSlice.reducer;