// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addLocalInvoice, addLocalInvoicePdf, editLocalInvoiceStatus, deleteLocalInvoice, editLocalInvoice, getAllLocalInvoices, getLocalInvoice } from "../request/localInvoiceRequest";

export const fetchAllLocalInvoices = createAsyncThunk('import/fetchAllLocalInvoices', async () => {

    const response = await getAllLocalInvoices()
    return response.data.items
})



export const searchLocalInvoice = createAsyncThunk('import/searchLocalInvoice', async (payload) => {

    const response = await getLocalInvoice(payload)
    return response.data
})

export const newLocalInvoice = createAsyncThunk('import/newLocalInvoice', async (payload, thunkAPI) => {

    try {
        const response = await addLocalInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateLocalInvoice = createAsyncThunk('import/updateLocalInvoice', async (payload, thunkAPI) => {

    try {
        const response = await editLocalInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeLocalInvoice = createAsyncThunk('import/removeLocalInvoice', async (payload) => {
    const response = await deleteLocalInvoice(payload)
    return {}
})

export const updateLocalInvoiceStatus = createAsyncThunk('import/updateLocalInvoiceStatus', async (payload, thunkAPI) => {

    try {
        const response = await editLocalInvoiceStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadLocalInvoicePdf = createAsyncThunk('import/uploadLocalInvoicePdf', async (payload, thunkAPI) => {

    try {
        const response = await addLocalInvoicePdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const localInvoice = createSlice({
    name: "bl",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Payment Ticket :
        builder
            .addCase(fetchAllLocalInvoices.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllLocalInvoices.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllLocalInvoices.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Payment Ticket :
        builder
            .addCase(searchLocalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchLocalInvoice.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchLocalInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Payment Ticket :
        builder
            .addCase(newLocalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newLocalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newLocalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Payment Ticket:
        builder
            .addCase(updateLocalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateLocalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateLocalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Payment Ticket:
        builder
            .addCase(removeLocalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeLocalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeLocalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Payment Ticket Status :
        builder
            .addCase(updateLocalInvoiceStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateLocalInvoiceStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateLocalInvoiceStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Upload Payment Ticket Pdf :
        builder
            .addCase(uploadLocalInvoicePdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadLocalInvoicePdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadLocalInvoicePdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default localInvoice.reducer;