// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addImportProducts, deleteImportProducts, editImportProducts, getAllImportProducts, getImportProductByName } from "../request/importProductRequest";



export const fetchAllImportProducts = createAsyncThunk('import/fetchAllImportProducts', async (data) => {

    const response = await getAllImportProducts(data)
    return response.data.items
})


export const searchImportProductByName = createAsyncThunk('import/searchImportProductByName', async (data) => {

    const response = await getImportProductByName(data)
    return response.data
})


export const newImportProduct = createAsyncThunk('import/newImportProduct', async (payload, thunkAPI) => {

    try {
        const response = await addImportProducts(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateImportProduct = createAsyncThunk('import/updateImportProduct', async (payload, thunkAPI) => {

    try {
        const response = await editImportProducts(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeImportProduct = createAsyncThunk('import/removeImportProduct', async (data) => {

    const response = await deleteImportProducts(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const importProductSlice = createSlice({
    name: "importProducts",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchAllImportProducts.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllImportProducts.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllImportProducts.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Import Product By name :
        builder
            .addCase(searchImportProductByName.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchImportProductByName.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchImportProductByName.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Import Product :
        builder
            .addCase(newImportProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newImportProduct.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newImportProduct.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Import Product :
        builder
            .addCase(updateImportProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateImportProduct.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateImportProduct.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Import Product :
        builder
            .addCase(removeImportProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeImportProduct.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeImportProduct.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default importProductSlice.reducer;