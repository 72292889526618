//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getSuppliers, addSupplier, editSupplier, deleteSupplier } from "../request/supplierRequest";

export const fetchAllSuppliers = createAsyncThunk('supplier/fetchSupplier', async () => {
    var response = await getSuppliers()
    return response.data.items
})

export const newSupplier = createAsyncThunk('supplier/newSupplier', async (data, thunkAPI) => {
    try {
        const response = await addSupplier(data)

        return thunkAPI.fulfillWithValue(data)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})

export const updateSupplier = createAsyncThunk('supplier/updateSupplier', async (data) => {
    var response = await editSupplier(data)
    return {}

})

export const removeSupplier = createAsyncThunk('supplier/removeSupplier', async (data) => {
    var response = await deleteSupplier(data)
    return {}
})


const initialState = {
    info: [],
    status: 'idle',
    type: '',
    errors: {}
}

//Reducers
const supplierSlice = createSlice({
    name: "supplier",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Supplier :
        builder
            .addCase(fetchAllSuppliers.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSuppliers.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllSuppliers.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Supplier :
        builder
            .addCase(newSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSupplier.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newSupplier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })


        //Update Supplier :
        builder
            .addCase(updateSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSupplier.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSupplier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "put", status: "failed" }
            })

        //Remove Supplier :
        builder
            .addCase(removeSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSupplier.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSupplier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })

    }
})

export default supplierSlice.reducer;