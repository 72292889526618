// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addVat, getAllVats, editVat, deleteVat } from "../request/vatRequest";

export const fetchAllVats = createAsyncThunk('vat/fetchAllVats', async () => {

    const response = await getAllVats()
    return response.data.items
})

export const newVat = createAsyncThunk('vat/newVat', async (payload, thunkAPI) => {

    try {
        const response = await addVat(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateVat = createAsyncThunk('vat/updateVat', async (payload, thunkAPI) => {

    try {
        const response = await editVat(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeVat = createAsyncThunk('vat/removeVat', async (payload, thunkAPI) => {

    try {
        const response = await deleteVat(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})




const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const vatSlice = createSlice({
    name: "vat",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Vats :

        builder
            .addCase(fetchAllVats.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllVats.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllVats.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Vat :
        builder
            .addCase(newVat.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newVat.fulfilled, (state, action) => {
                return { ...state, info: [action.payload, ...state.info], type: "post", status: "succeded" }
            })
            .addCase(newVat.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Vat :
        builder
            .addCase(updateVat.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateVat.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateVat.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Remove Tag :
        builder
            .addCase(removeVat.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeVat.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeVat.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })
    }
})

export default vatSlice.reducer;