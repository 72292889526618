// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addDelivery, deleteDelivery, editDelivery, getAllDelivery, getDelivery, addSalesDeliveryPdf} from "../request/salesDeliveryRequest";



export const fetchAllSalesDelivery = createAsyncThunk('sale/fetchAllSalesDelivery', async (data) => {

    const response = await getAllDelivery(data)
    return response.data.items
})

export const searchSalesDelivery = createAsyncThunk('sale/searchSalesDelivery', async (payload) => {

    const response = await getDelivery(payload)
    return response.data
})

export const createSalesDelivery = createAsyncThunk('sale/createSalesDelivery', async (payload, thunkAPI) => {

    try {
        const response = await addDelivery(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesDelivery = createAsyncThunk('import/updateSalesDelivery', async (payload, thunkAPI) => {

    try {
        const response = await editDelivery(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeSalesDelivery = createAsyncThunk('import/removeSalesDelivery', async (payload) => {
    const response = await deleteDelivery(payload)
    return {}
})

export const uploadSalesDeliveryPdf = createAsyncThunk('import/uploadSalesDeliveryPdf', async (payload, thunkAPI) => {

    try {
        const response = await addSalesDeliveryPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],

    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesDeliverySlice = createSlice({
    name: "salesDeliverySlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Delivery :
        builder
            .addCase(fetchAllSalesDelivery.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesDelivery.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesDelivery.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Delivery :
        builder
            .addCase(searchSalesDelivery.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesDelivery.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesDelivery.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Create  Sales Delivery :
        builder
            .addCase(createSalesDelivery.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(createSalesDelivery.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(createSalesDelivery.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post", status: "failed" }
            })

        //Edit Sales Delivery  :
        builder
            .addCase(updateSalesDelivery.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesDelivery.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesDelivery.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Sales Delivery :
        builder
            .addCase(removeSalesDelivery.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesDelivery.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesDelivery.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Sales Delivery Pdf :
        builder
            .addCase(uploadSalesDeliveryPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadSalesDeliveryPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadSalesDeliveryPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
        

    }
})

export default salesDeliverySlice.reducer;