import { useMsal } from '@azure/msal-react';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom'

import { tokenRequest } from './_authConfig';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useDispatch } from 'react-redux';
import { fetchTenant } from './redux/ducks/tenantSlice';
import { fetchProfile, newProfile } from './redux/ducks/profileSlice';

const LandingModule = React.lazy(() => import('./modules/LandingModule'))
const DashboardModule = React.lazy(() => import('./modules/DashboardModule'));
const UsersModule = React.lazy(() => import('./modules/UsersModule'));
const SettingModule = React.lazy(() => import('./modules/SettingModule'));
const ProfileModule = React.lazy(() => import('./modules/ProfileModule'));
const StockModule = React.lazy(() => import('./modules/StockModule'));
const PurchasingModule = React.lazy(() => import('./modules/PurchasingModule'));
const ImportModule = React.lazy(() => import('./modules/ImportModule'));
const SalesModule = React.lazy(() => import('./modules/SalesModule'));
const BankModule = React.lazy(() => import('./modules/BankModule'));
const ProductionModule = React.lazy(() => import('./modules/ProductionModule'));



function App() {

  const { instance, accounts } = useMsal();


  const dispatch = useDispatch()

  useEffect(() => {

    if (accounts.length > 0) {
      instance.acquireTokenSilent({
        ...tokenRequest,
        account: accounts[0],
      }).then(res => {
        localStorage.setItem('token', res.accessToken)
        dispatch(fetchTenant())
      })
    }

  }, [accounts])




  return (

    <Suspense fallback={<div></div>}>
      <Router>
        <Switch>
          <Route exact path='/' component={LandingModule} />
          <Route path='/dashboard' component={DashboardModule} />
          <Route path='/users' component={UsersModule} />
          <Route path='/settings' component={SettingModule} />
          <Route path='/profile' component={ProfileModule} />
          <Route path='/stock' component={StockModule} />
          <Route path='/purchasing' component={PurchasingModule} />
          <Route path='/import' component={ImportModule} />
          <Route path='/sales' component={SalesModule} />
          <Route path='/bank' component={BankModule} />
          <Route path='/production' component={ProductionModule} />
        </Switch>
      </Router >
    </Suspense >

  );
}

export default App;
