//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getWarehouse, addWarehouse, deleteWarehouse, editWarehouse } from "../request/warehouseRequest";

export const fetchWarehouse = createAsyncThunk('warehouse/fetchWarehouse', async () => {
    var response = await getWarehouse()
    return response.data.items
})

export const newWarehouse = createAsyncThunk('warehouse/newWarehouse', async (data, thunkAPI) => {
    try {
        const response = await addWarehouse(data)

        return thunkAPI.fulfillWithValue(data)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})

export const updateWarehouse = createAsyncThunk('warehouse/updateWarehouse', async (data) => {
    var response = await editWarehouse(data)
    return {}

})

export const removeWarehouse = createAsyncThunk('warehouse/removeWarehouse', async (data) => {
    var response = await deleteWarehouse(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: '',
    errors: {}
}

//Reducers
const warehouseSlice = createSlice({
    name: "warehouse",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Warehouse :
        builder
            .addCase(fetchWarehouse.pending, (state, action) => {
                return { ...state, status: "loading", type: "get" , errors: {}}
            })
            .addCase(fetchWarehouse.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchWarehouse.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Warehouse :
        builder
            .addCase(newWarehouse.pending, (state, action) => {
                return { ...state, status: "loading", type: "post" , errors: {} }
            })
            .addCase(newWarehouse.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newWarehouse.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Warehouse :
        builder
            .addCase(updateWarehouse.pending, (state, action) => {
                return { ...state, status: "loading", type: "put" , errors: {}}
            })
            .addCase(updateWarehouse.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateWarehouse.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "put", status: "failed" }
            })

        //Remove Warehouse :
        builder
            .addCase(removeWarehouse.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete" , errors: {}}
            })
            .addCase(removeWarehouse.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeWarehouse.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })


    }
})

export default warehouseSlice.reducer;