// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addComment, deleteComment, editComment, getAllComments } from "../request/CommentRequest";


export const fetchAllComments = createAsyncThunk('comment/fetchComments', async (payload) => {
       var response = await getAllComments(payload)

    return response.data.items
})


export const addCommentAsync = createAsyncThunk('comment/newComments', async (payload, thunkAPI) => {

    try {

        const response = await addComment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeCommentAsync = createAsyncThunk('comment/removeComment', async (commentId, { getState, requestId }) => {
    const response = await deleteComment(commentId)
    return response.data
  }
)

export const editCommentAsync = createAsyncThunk('comment/editComment',  async ({ commentId, text }, thunkAPI) => {
      try {
        const com = { id: commentId, data: { text } }; 
        const response = await editComment(com); 
        
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
      }
    }
  );

  const initialState = {
    info: [],
    item: {},
    status: 'idle',
    type: null,
    errors: {},
}


//Reducers
const CommentsSlice = createSlice({
    name: "comments",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

       //fetch Comments :

            builder
                .addCase(fetchAllComments.pending, (state, action) => {
                    return { ...state, status: "loading", type: "get" }
                })
                .addCase(fetchAllComments.fulfilled, (state, action) => {
                    return { ...state, info: { ...action.payload }, type: "get", status: "succeded" }
                })
                .addCase(fetchAllComments.rejected, (state, action) => {
                    return { ...state, errors: action.payload, type: "get", status: "failed" }
                })
     

        //New Comments :
        builder
        .addCase(addCommentAsync.pending, (state, action) => {
            return { ...state, status: "loading", type: "post", errors: {} }
        })
        .addCase(addCommentAsync.fulfilled, (state, action) => {
            return {
                ...state, type: "post", status: "succeded"
            }
        })
        .addCase(addCommentAsync.rejected, (state, { payload }) => {
            return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
        })
    
       //delete Comments :
        builder
           .addCase(removeCommentAsync.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeCommentAsync.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeCommentAsync.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Edit Comments :
        builder
            .addCase(editCommentAsync.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_Comments", errors: {} }
            })
            .addCase(editCommentAsync.fulfilled, (state, action) => {
                return { ...state, type: "put_Comments", status: "succeded" }
            })
            .addCase(editCommentAsync.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_Comments", status: "failed" }
            })

     
    }
})

export default CommentsSlice.reducer;