// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesTransactionImpaye, addSalesTransactionPdf, addSalesTransactionRepresentation, addSalesTransactionRestitution, getAllSalesTransaction, getSalesTransaction, addSalesTransactionContreImpaye, editStatusTransaction } from "../request/salesTransactionRequest";



export const fetchAllSalesTransaction = createAsyncThunk('sale/fetchAllSalesTransaction', async (data) => {

    const response = await getAllSalesTransaction(data)
    return response.data.items
})

export const searchSalesTransaction = createAsyncThunk('sale/searchSalesTransaction', async (payload) => {

    const response = await getSalesTransaction(payload)
    return response.data
})

export const createSalesTransactionImpaye = createAsyncThunk('sale/createSalesTransactionImpaye', async (payload, thunkAPI) => {
console.log(payload)
    try {
        const response = await addSalesTransactionImpaye(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const createSalesTransactionRepresentation = createAsyncThunk('sale/createSalesTransactionRepresentation', async (payload, thunkAPI) => {

    try {
        const response = await addSalesTransactionRepresentation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const createSalesTransactionRestitution = createAsyncThunk('sale/createSalesTransactionRestitution', async (payload, thunkAPI) => {

    try {
        console.log(payload)
        const response = await addSalesTransactionRestitution(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const createSalesTransactionContreImpaye = createAsyncThunk('sale/createSalesTransactionContreImpaye', async (payload, thunkAPI) => {

    try {
        const response = await addSalesTransactionContreImpaye(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const uploadSalesTransactionPdf = createAsyncThunk('sale/uploadSalesTransactionPdf', async (payload, thunkAPI) => {

    try {
        const response = await addSalesTransactionPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateStatusTransaction = createAsyncThunk('sale/updateStatusTransaction', async (payload, thunkAPI) => {

    try {
 
        const response = await editStatusTransaction(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})




const initialState = {
    info: [],

    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesTransactionSlice = createSlice({
    name: "salesTransactionSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Transaction :
        builder
            .addCase(fetchAllSalesTransaction.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesTransaction.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesTransaction.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Transaction :
        builder
            .addCase(searchSalesTransaction.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesTransaction.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesTransaction.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Create  Sales Transaction Impaye:
        builder
            .addCase(createSalesTransactionImpaye.pending, (state, action) => {
                return { ...state, status: "loading", type: "post_impaye", errors: {} }
            })
            .addCase(createSalesTransactionImpaye.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "post_impaye", status: "succeded" }
            })
            .addCase(createSalesTransactionImpaye.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post_impaye", status: "failed" }
            })

        //Create  Sales Transaction Representation:
        builder
            .addCase(createSalesTransactionRepresentation.pending, (state, action) => {
                return { ...state, status: "loading", type: "post_representation", errors: {} }
            })
            .addCase(createSalesTransactionRepresentation.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "post_representation", status: "succeded" }
            })
            .addCase(createSalesTransactionRepresentation.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post_representation", status: "failed" }
            })

        //Create  Sales Transaction Restitution:
        builder
            .addCase(createSalesTransactionRestitution.pending, (state, action) => {
                return { ...state, status: "loading", type: "post_restitution", errors: {} }
            })
            .addCase(createSalesTransactionRestitution.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "post_restitution", status: "succeded" }
            })
            .addCase(createSalesTransactionRestitution.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post_restitution", status: "failed" }
            })

        //Create  Sales Transaction ContreImpaye:
        builder
            .addCase(createSalesTransactionContreImpaye.pending, (state, action) => {
                return { ...state, status: "loading", type: "post_contreImpaye", errors: {} }
            })
            .addCase(createSalesTransactionContreImpaye.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "post_contreImpaye", status: "succeded" }
            })
            .addCase(createSalesTransactionContreImpaye.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post_contreImpaye", status: "failed" }
            })

        //Upload Sales Transaction Pdf :
        builder
            .addCase(uploadSalesTransactionPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadSalesTransactionPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadSalesTransactionPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })

         builder
            .addCase(updateStatusTransaction.pending, (state, action) => {
                return { ...state, status: "loading", type: "update_status", errors: {} }
            })
            .addCase(updateStatusTransaction.fulfilled, (state, action) => {
                return { ...state, type: "update_status", status: "succeded" }
            })
            .addCase(updateStatusTransaction.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "update_status", status: "failed" }
            })

        }
})

export default salesTransactionSlice.reducer;