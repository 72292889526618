// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addInventaire, deleteInventaire, editInventaire, getAllInventaires, getInventaire } from "../request/inventaireRequest";



export const fetchAllInventaires = createAsyncThunk('import/fetchAllInventaires', async (data) => {

    const response = await getAllInventaires(data)
    return response.data.items
})

export const searchInventaire = createAsyncThunk('import/searchInventaire', async (payload) => {

    const response = await getInventaire(payload)
    return response.data
})

export const newInventaire = createAsyncThunk('import/newInventaire', async (payload, thunkAPI) => {

    try {
        const response = await addInventaire(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateInventaire = createAsyncThunk('import/updateInventaire', async (payload, thunkAPI) => {

    try {
        const response = await editInventaire(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeInventaire = createAsyncThunk('import/removeInventaire', async (data) => {

    const response = await deleteInventaire(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const InventaireSlice = createSlice({
    name: "inventaire",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchAllInventaires.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllInventaires.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllInventaires.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Inventaire :

        builder
            .addCase(searchInventaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchInventaire.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchInventaire.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Inventaire :
        builder
            .addCase(newInventaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newInventaire.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newInventaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Inventaire :
        builder
            .addCase(updateInventaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateInventaire.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateInventaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Inventaire :
        builder
            .addCase(removeInventaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeInventaire.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeInventaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default InventaireSlice.reducer;