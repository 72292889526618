// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editBankCollection, editBankCollectionSendBank, editBankCollectionSentBank } from "../request/bankCollectionRequest";

export const updateBankCollection = createAsyncThunk('import/updateBankCollection', async (payload, thunkAPI) => {

    try {
        const response = await editBankCollection(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateBankCollectionSendBank = createAsyncThunk('import/updateBankCollectionSendBank', async (payload, thunkAPI) => {

    try {
        const response = await editBankCollectionSendBank(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateBankCollectionSentBank = createAsyncThunk('import/updateBankCollectionSentBank', async (payload, thunkAPI) => {

    try {
        const response = await editBankCollectionSentBank(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})


const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const BankCollectionSlice = createSlice({
    name: "BankCollectionSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Edit Bank Collection :
        builder
            .addCase(updateBankCollection.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBankCollection.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBankCollection.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Edit Bank Collection :
        builder
            .addCase(updateBankCollectionSendBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBankCollectionSendBank.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBankCollectionSendBank.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Edit Bank Collection :
        builder
            .addCase(updateBankCollectionSentBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBankCollectionSentBank.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBankCollectionSentBank.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

    }
})

export default BankCollectionSlice.reducer;