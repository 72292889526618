// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesCustomer, deleteSalesCustomer, editSalesCustomer, editSalesCustomerPermissions, editSalesCustomerStatus, getAllSalesCustomerel, getSalesCustomer } from "../request/salesCustomerRequest";




export const fetchAllSalesCustomer = createAsyncThunk('import/fetchAllSalesCustomer', async (data) => {

    const response = await getAllSalesCustomerel(data)
    return response.data.items
})

export const searchSalesCustomer = createAsyncThunk('import/searchSalesCustomer', async (payload) => {

    const response = await getSalesCustomer(payload)
    return response.data
})


export const newSalesCustomer = createAsyncThunk('import/newSalesCustomer', async (payload, thunkAPI) => {

    try {
        const response = await addSalesCustomer(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSalesCustomer = createAsyncThunk('import/updateSalesCustomer', async (payload, thunkAPI) => {

    try {
        const response = await editSalesCustomer(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesCustomerStatus = createAsyncThunk('import/updateSalesCustomerStatus', async (payload, thunkAPI) => {

    try {
        const response = await editSalesCustomerStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesCustomerPermissions = createAsyncThunk('import/updateSalesCustomerPermissions', async (payload, thunkAPI) => {

    try {
        const response = await editSalesCustomerPermissions(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeSalesCustomer = createAsyncThunk('import/removeSalesCustomer', async (payload) => {
    const response = await deleteSalesCustomer(payload)
    return {}
})


const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesCustomerSlice = createSlice({
    name: "salesCustomerSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Customer :
        builder
            .addCase(fetchAllSalesCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesCustomer.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesCustomer.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Customer :
        builder
            .addCase(searchSalesCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesCustomer.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesCustomer.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Sales Customer :
        builder
            .addCase(newSalesCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSalesCustomer.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSalesCustomer.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Sales Customer :
        builder
            .addCase(updateSalesCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesCustomer.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesCustomer.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Edit Sales Customer Status :
        builder
            .addCase(updateSalesCustomerStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateSalesCustomerStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateSalesCustomerStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Edit Sales Customer Permissions :
        builder
            .addCase(updateSalesCustomerPermissions.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_permissions", errors: {} }
            })
            .addCase(updateSalesCustomerPermissions.fulfilled, (state, action) => {
                return { ...state, type: "put_permissions", status: "succeded" }
            })
            .addCase(updateSalesCustomerPermissions.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_permissions", status: "failed" }
            })

        //Delete Sales Customer :
        builder
            .addCase(removeSalesCustomer.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesCustomer.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesCustomer.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default salesCustomerSlice.reducer;