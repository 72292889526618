// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAlltransactionMovement } from "../request/transactionMovmtResquest";



export const fetchAllTransactionMovmt = createAsyncThunk('tag/fetchAllTransactionMovmt', async () => {

    const response = await getAlltransactionMovement()
    return response.data.items
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const transactionMovementSlice = createSlice({
    name: "tag",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Tags :

        builder
            .addCase(fetchAllTransactionMovmt.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllTransactionMovmt.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllTransactionMovmt.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })
    }
})

export default transactionMovementSlice.reducer;