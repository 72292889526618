// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBatch, deleteBatch, editBatch, getAllBatches } from "../request/batchRequest";

// Async actions
export const fetchAllBatches = createAsyncThunk('batch/fetchAllBatches', async () => {
    const response = await getAllBatches();
    return response.data.items;
});

export const newBatch = createAsyncThunk('batch/newBatch', async (payload, thunkAPI) => {
    try {
        const response = await addBatch(payload);
        return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

export const updateBatch = createAsyncThunk('batch/updateBatch', async (payload, thunkAPI) => {
    try {
        const response = await editBatch(payload);
        return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

export const removeBatch = createAsyncThunk('batch/removeBatch', async (id, thunkAPI) => {
    try {
        await deleteBatch(id);
        return thunkAPI.fulfillWithValue(id);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
};

// Reducer slice
const batchSlice = createSlice({
    name: "batch",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        
            //Fetch All Bill of Matrial :
        builder
        .addCase(fetchAllBatches.pending, (state, action) => {
            return { ...state, status: "loading", type: "get", errors: {} }
        })
        .addCase(fetchAllBatches.fulfilled, (state, action) => {
            return { ...state, info: action.payload, type: "get", status: "succeded" }
        })
        .addCase(fetchAllBatches.rejected, (state, action) => {
            return { ...state, errors: action.payload, type: "get", status: "failed" }
        })


      //New Bill of Matrial :
    builder
        .addCase(newBatch.pending, (state, action) => {
            return { ...state, status: "loading", type: "post", errors: {} }
        })
        .addCase(newBatch.fulfilled, (state, action) => {
            return { ...state, type: "post", status: "succeded" }
        })
        .addCase(newBatch.rejected, (state, { payload }) => {
            return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
        })

    //Edit Bill of lading:
    builder
        .addCase(updateBatch.pending, (state, action) => {
            return { ...state, status: "loading", type: "put", errors: {} }
        })
        .addCase(updateBatch.fulfilled, (state, action) => {
            return { ...state, type: "put", status: "succeded" }
        })
        .addCase(updateBatch.rejected, (state, { payload }) => {
            return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
        })

    //Delete Bill of lading:
    builder
        .addCase(removeBatch.pending, (state, action) => {
            return { ...state, status: "loading", type: "delete", errors: {} }
        })
        .addCase(removeBatch.fulfilled, (state, action) => {
            return { ...state, type: "delete", status: "succeded" }
        })
        .addCase(removeBatch.rejected, (state, { payload }) => {
            return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
        })

    }
});

export default batchSlice.reducer;
