import axios from "axios";
import config from "../../_config.json"

const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}


export const getAllSalesLoading = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading`
    })
};

export const getSalesLoading = async (payload) => {

    let token = localStorage.getItem('token')


    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading/${payload}`
    })
};

export const addSalesLoading = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading`,
        data: payload
    })
};

export const editSalesLoading = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading/${payload.id}`,
        data: payload.data
    })
};

export const deleteSalesLoading = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading/${payload}`,
    })
};

export const addSalesLoadingPdf = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesLoading/${payload.id}/pdf`,
        data: payload.data
    })
};