// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllTransitaires, addTransitaire, getTransitaire, editTransitaire, deleteTransitaire } from "../request/transitaireRequest";

export const fetchAllTransitaires = createAsyncThunk('import/fetchAllTransitaires', async () => {

    const response = await getAllTransitaires()
    return response.data.items
})

export const searchTransitaire = createAsyncThunk('import/searchTransitaire', async (payload) => {

    const response = await getTransitaire(payload)
    return response.data
})

export const newTransitaire = createAsyncThunk('import/newTransitaire', async (payload, thunkAPI) => {

    try {
        const response = await addTransitaire(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateTransitaire = createAsyncThunk('import/updateTransitaire', async (payload, thunkAPI) => {

    try {
        const response = await editTransitaire(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeTransitaire = createAsyncThunk('import/removeTransitaire', async (data) => {

    const response = await deleteTransitaire(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const transitatireSlice = createSlice({
    name: "transitaire",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Transitaire :

        builder
            .addCase(fetchAllTransitaires.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllTransitaires.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllTransitaires.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Transitaire :

        builder
            .addCase(searchTransitaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchTransitaire.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchTransitaire.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Add Transitaire:

        builder
            .addCase(newTransitaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newTransitaire.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newTransitaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Transitaire:

        builder
            .addCase(updateTransitaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateTransitaire.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateTransitaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete Transitaire:

        builder
            .addCase(removeTransitaire.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeTransitaire.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeTransitaire.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })
    }
})

export default transitatireSlice.reducer;