// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addFicheLiquidation, addFicheLiquidationPdf, deleteFicheLiquidation, editFicheLiquidation, getAllFicheLiquidations, getFicheLiquidation } from "../request/FicheLiquidationRequest";

export const fetchAllFicheLiquidations = createAsyncThunk('import/fetchAllFicheLiquidations', async () => {

    const response = await getAllFicheLiquidations()
    return response.data.items
})



export const searchFicheLiquidation = createAsyncThunk('import/searchFicheLiquidation', async (payload) => {

    const response = await getFicheLiquidation(payload)
    return response.data
})

export const newFicheLiquidation = createAsyncThunk('import/newFicheLiquidation', async (payload, thunkAPI) => {

    try {
        const response = await addFicheLiquidation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateFicheLiquidation = createAsyncThunk('import/updateFicheLiquidation', async (payload, thunkAPI) => {

    try {
        const response = await editFicheLiquidation(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeFicheLiquidation = createAsyncThunk('import/removeFicheLiquidation', async (payload) => {
    const response = await deleteFicheLiquidation(payload)
    return {}
})


export const uploadFicheLiquidationPdf = createAsyncThunk('import/uploadFicheLiquidationPdf', async (payload, thunkAPI) => {

    try {
        const response = await addFicheLiquidationPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    bon: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const FicheLiquidationSlice = createSlice({
    name: "fiche",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Fiche de liquidation :
        builder
            .addCase(fetchAllFicheLiquidations.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllFicheLiquidations.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllFicheLiquidations.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Fiche de liquidation :
        builder
            .addCase(searchFicheLiquidation.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchFicheLiquidation.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchFicheLiquidation.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Fiche de liquidation :
        builder
            .addCase(newFicheLiquidation.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newFicheLiquidation.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newFicheLiquidation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Fiche de liquidation:
        builder
            .addCase(updateFicheLiquidation.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateFicheLiquidation.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateFicheLiquidation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Fiche de liquidation:
        builder
            .addCase(removeFicheLiquidation.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeFicheLiquidation.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeFicheLiquidation.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Fiche de liquidation Pdf :
        builder
            .addCase(uploadFicheLiquidationPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadFicheLiquidationPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadFicheLiquidationPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })

    }
})

export default FicheLiquidationSlice.reducer;