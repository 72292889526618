// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesOrder, deleteSalesOrder, editSalesOrder, getAllSalesOrder, editSalesOrderStatus, getSalesOrder, getAllSalesOrderBon, getSalesOrderBonDeliverd, getSalesOrderCalcules, addQuickOrder } from "../request/salesOrderRequest";




export const fetchAllSalesOrder = createAsyncThunk('import/fetchAllSalesOrder', async (data) => {

    const response = await getAllSalesOrder(data)
    return response.data.items
})

export const searchSalesOrder = createAsyncThunk('import/searchSalesOrder', async (payload) => {

    const response = await getSalesOrder(payload)
    return response.data
})

export const searchSalesOrderCalcule = createAsyncThunk('import/searchSalesOrderCalcule', async (payload) => {

    const response = await getSalesOrderCalcules(payload)
    return response.data
})

export const searchSalesOrderBon = createAsyncThunk('import/searchSalesOrderBon', async (payload) => {

    const response = await getAllSalesOrderBon(payload)
    return response.data.items
})

export const searchSalesOrderBonDelivered = createAsyncThunk('import/searchSalesOrderBonDelivered', async (payload) => {

    const response = await getSalesOrderBonDeliverd(payload)
    return response.data
})

export const newSalesOrder = createAsyncThunk('import/newSalesOrder', async (payload, thunkAPI) => {

    try {
        const response = await addSalesOrder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const newQuickOrder = createAsyncThunk('import/newQuickOrder', async (payload, thunkAPI) => {

    try {
        const response = await addQuickOrder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSalesOrder = createAsyncThunk('import/updateSalesOrder', async (payload, thunkAPI) => {

    try {
        const response = await editSalesOrder(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesOrderStatus = createAsyncThunk('import/updateSalesOrderStatus', async (payload, thunkAPI) => {

    try {
        const response = await editSalesOrderStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesOrderPermissions = createAsyncThunk('import/updateSalesOrderPermissions', async (payload, thunkAPI) => {

    try {
        const response = await editSalesOrderPermissions(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeSalesOrder = createAsyncThunk('import/removeSalesOrder', async (payload) => {
    const response = await deleteSalesOrder(payload)
    return {}
})



const initialState = {
    info: [],
    bons: [],
    data: {},
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesOrderSlice = createSlice({
    name: "salesOrderSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Order :
        builder
            .addCase(fetchAllSalesOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesOrder.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesOrder.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Order :
        builder
            .addCase(searchSalesOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesOrder.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesOrder.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Seach  Sales Order Calcul :
        builder
        .addCase(searchSalesOrderCalcule.pending, (state, action) => {
            return { ...state, status: "loading", type: "search", errors: {} }
        })
        .addCase(searchSalesOrderCalcule.fulfilled, (state, action) => {
            return { ...state, info: [action.payload], type: "search", status: "succeded" }
        })
        .addCase(searchSalesOrderCalcule.rejected, (state, action) => {
            return { ...state, errors: action.payload, type: "search", status: "failed" }
        })

        //Seach  Sales Order Bon :
        builder
            .addCase(searchSalesOrderBon.pending, (state, action) => {
                return { ...state, status: "loading", type: "search_bon", errors: {} }
            })
            .addCase(searchSalesOrderBon.fulfilled, (state, action) => {
                return { ...state, bons: action.payload, type: "search_bon", status: "succeded" }
            })
            .addCase(searchSalesOrderBon.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search_bon", status: "failed" }
            })

        //Seach  Sales Order Bon Deivered:
        builder
            .addCase(searchSalesOrderBonDelivered.pending, (state, action) => {
                return { ...state, status: "loading", type: "bon_delivered", errors: {} }
            })
            .addCase(searchSalesOrderBonDelivered.fulfilled, (state, action) => {
                return { ...state, data: action.payload, type: "bon_delivered", status: "succeded" }
            })
            .addCase(searchSalesOrderBonDelivered.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "bon_delivered", status: "failed" }
            })

        //New Sales Order :
        builder
            .addCase(newSalesOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSalesOrder.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSalesOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //New Quick Order :
        builder
        .addCase(newQuickOrder.pending, (state, action) => {
            return { ...state, status: "loading", type: "post", errors: {} }
        })
        .addCase(newQuickOrder.fulfilled, (state, action) => {
            return { ...state, type: "post", status: "succeded" }
        })
        .addCase(newQuickOrder.rejected, (state, { payload }) => {
            return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
        })

        //Edit Sales Order :
        builder
            .addCase(updateSalesOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesOrder.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Edit Sales Order Status :
        builder
            .addCase(updateSalesOrderStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateSalesOrderStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateSalesOrderStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Edit Sales Order Permissions :
        builder
            .addCase(updateSalesOrderPermissions.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_permissions", errors: {} }
            })
            .addCase(updateSalesOrderPermissions.fulfilled, (state, action) => {
                return { ...state, type: "put_permissions", status: "succeded" }
            })
            .addCase(updateSalesOrderPermissions.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_permissions", status: "failed" }
            })

        //Delete Sales Order :
        builder
            .addCase(removeSalesOrder.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesOrder.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesOrder.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

      
    }
})

export default salesOrderSlice.reducer;