// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDefaultedBalance, createDefaultBalance } from "../request/salesDefaultBalanceRequest";

export const searchDefaultedBalance = createAsyncThunk('sales/defaulted_balance', async (payload) => {

    const response = await getDefaultedBalance(payload)
    return response.data.items
})

export const saveDefaultedBalance = createAsyncThunk('sales/saveDefaultedBalance', async (payload, thunkAPI) => {

    try {
        const response = await createDefaultBalance(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const defaultBalanceSlice = createSlice({
    name: "defaultBalance",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Seach  Default  Balance :
        builder
            .addCase(searchDefaultedBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchDefaultedBalance.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "search", status: "succeded" }
            })
            .addCase(searchDefaultedBalance.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Save Default Balance :
        builder
            .addCase(saveDefaultedBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "save", errors: {} }
            })
            .addCase(saveDefaultedBalance.fulfilled, (state, action) => {
                return { ...state, type: "save", status: "succeded" }
            })
            .addCase(saveDefaultedBalance.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "save", status: "failed" }
            })
    }
})

export default defaultBalanceSlice.reducer;