//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addDueDate, deleteDueDate, getAllDueDate, getDueDate, getDueDatebyTransaction, updateDueDateRequestStatus } from "../request/DueDateRequests";

export const fetchAllDueDate = createAsyncThunk('import/fetchAllDueDate', async () => {
    const response = await getAllDueDate();
    return response.data.items;
});


export const fetchDueDateById = createAsyncThunk('import/searchDueDate', async (payload) => {

    const response = await getDueDate(payload)
    return response.data
})

export const fetchDueDateByTransactionId = createAsyncThunk('import/searchDueDateByTransaction', async (payload) => {

    const response = await getDueDatebyTransaction(payload)
    return response.data
})

export const updateStatusDueDateRequest = createAsyncThunk('import/updateDueDate', async ({ id, data }, thunkAPI) => {
    try {
        const response = await updateDueDateRequestStatus(id, data);
        return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

export const addDueDateRequest = createAsyncThunk('import/newDueDate', async (payload, thunkAPI) => {
    try {
        const response = await addDueDate(payload);
        return thunkAPI.fulfillWithValue(payload);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

export const removeDueDateRequest = createAsyncThunk('import/removeDueDate', async (payload, thunkAPI) => {
    try {
        const response = await deleteDueDate(payload);
        return thunkAPI.fulfillWithValue(payload);
    } catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
});

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {},
    deleteStatus: 'idle'
};

const dueDateSlice = createSlice({
    name: "dueDate",
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        // Fetch Due Date Request
        builder
            .addCase(fetchAllDueDate.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(fetchAllDueDate.fulfilled, (state, action) => {
                state.info = action.payload;
                state.status = "succeeded";
            })
            .addCase(fetchAllDueDate.rejected, (state, action) => {
                state.errors = action.payload;
                state.status = "failed";
            });

        // New Due Date Request
        builder
            .addCase(addDueDateRequest.pending, (state, action) => {
                state.status = "loading";
            })
            .addCase(addDueDateRequest.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(addDueDateRequest.rejected, (state, { payload }) => {
                state.errors = payload.error.errors;
                state.status = "failed";
            });

        // Update Due Date Request
        builder
        .addCase(updateStatusDueDateRequest.pending, (state, action) => {
            state.status = "loading";
        })
        .addCase(updateStatusDueDateRequest.fulfilled, (state, action) => {
            state.status = "succeeded";
        })
        .addCase(updateStatusDueDateRequest.rejected, (state, { payload }) => {
            state.errors = payload.error.errors;
            state.status = "failed";
        });

        // Delete Due Date Request
        builder
            .addCase(removeDueDateRequest.pending, (state, action) => {
                state.deleteStatus = "loading";
            })
            .addCase(removeDueDateRequest.fulfilled, (state, action) => {
                state.deleteStatus = "succeeded";
            })
            .addCase(removeDueDateRequest.rejected, (state, { payload }) => {
                state.errors = payload.error.errors;
                state.deleteStatus = "failed";
            });
    }
});

export default dueDateSlice.reducer;
