//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}



export const getAllFicheLiquidations = async () => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation`
    })
};

export const getFicheLiquidation = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation/${payload}`
    })
};



export const addFicheLiquidation = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation`,
        data: payload
    })
};

export const editFicheLiquidation = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation/${payload.id}`,
        data: payload.data
    })
};

export const deleteFicheLiquidation = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation/${payload}`,
    })
};

export const addFicheLiquidationPdf = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/FicheLiquidation/${payload.id}/pdf`,
        data: payload.data
    })
};
