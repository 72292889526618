// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addFamily, deleteFamily, editFamily, getAllFamilies } from "../request/familyRequest";

export const fetchAllFamilies = createAsyncThunk('family/fetchAllFamilies', async () => {

    const response = await getAllFamilies()
    return response.data.items
})

export const newFamily = createAsyncThunk('family/newFamily', async (payload, thunkAPI) => {

    try {
        const response = await addFamily(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateFamily = createAsyncThunk('family/updateFamily', async (payload, thunkAPI) => {

    try {
        const response = await editFamily(payload)
        return thunkAPI.fulfillWithValue({})
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})

export const removeFamily = createAsyncThunk('family/removeFamily', async (payload) => {
    const response = await deleteFamily(payload)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const familySlice = createSlice({
    name: "family",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Families :

        builder
            .addCase(fetchAllFamilies.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllFamilies.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllFamilies.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Family :
        builder
            .addCase(newFamily.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newFamily.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newFamily.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })



        //Update Family :
        builder
            .addCase(updateFamily.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateFamily.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateFamily.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Remove Family :
        builder
            .addCase(removeFamily.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeFamily.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeFamily.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })

    }
})

export default familySlice.reducer;