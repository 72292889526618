// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllImportDashboardData, getImportDashboardData } from "../request/importDashboardRequest";



export const fetchAllImportDashboardData = createAsyncThunk('import/fetchAllImportDashboardData', async (data) => {

    const response = await getAllImportDashboardData()
    return response.data.items
})

export const searchImportDashboardData = createAsyncThunk('import/fetchImportDashboardData', async (data) => {

    const response = await getImportDashboardData(data)
    return response.data
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const importDashboard = createSlice({
    name: "importDashboard",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Import Dashboard :
        builder
            .addCase(fetchAllImportDashboardData.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllImportDashboardData.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllImportDashboardData.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Import Engagement :
        builder
            .addCase(searchImportDashboardData.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchImportDashboardData.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchImportDashboardData.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })
    }
})

export default importDashboard.reducer;