// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addPd, deletePd, editPd, getAllPds, getPdItem } from "../request/pdRequest";

export const fetchAllPds = createAsyncThunk('pd/fetchAllPds', async () => {

    const response = await getAllPds()
    return response.data.items
})

export const searchPdItem = createAsyncThunk('pd/searchPdItem', async (payload, thunkAPI) => {

    try {
        const response = await getPdItem(payload)
        return response.data
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const newPd = createAsyncThunk('pd/newPd', async (payload, thunkAPI) => {

    try {
        const response = await addPd(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updatePd = createAsyncThunk('pd/updatePd', async (payload, thunkAPI) => {

    try {
        const response = await editPd(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})




export const removePd = createAsyncThunk('pd/removePd', async (payload) => {
    const response = await deletePd(payload)
    return {}
})

const initialState = {
    info: [],
    item: {},
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const pdSlice = createSlice({
    name: "pd",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All PDs :

        builder
            .addCase(fetchAllPds.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllPds.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllPds.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Search  PD Item :

        builder
            .addCase(searchPdItem.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchPdItem.fulfilled, (state, action) => {
                return { ...state, item: { ...action.payload }, type: "search", status: "succeded" }
            })
            .addCase(searchPdItem.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })


        //New PD :
        builder
            .addCase(newPd.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newPd.fulfilled, (state, action) => {
                return {
                    ...state, type: "post", status: "succeded"
                }
            })
            .addCase(newPd.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update PD :
        builder
            .addCase(updatePd.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updatePd.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updatePd.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete PD :
        builder
            .addCase(removePd.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removePd.fulfilled, (state, action) => {
                return {
                    ...state, type: "delete", status: "succeded"
                }
            })
            .addCase(removePd.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

    }
})

export default pdSlice.reducer;