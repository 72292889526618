// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addDefects, deleteDefects, editDefects, getAllDefects, getDefects } from "../request/defectsRequest";



export const fetchAllDefects = createAsyncThunk('stock/fetchAllDefectss', async (data) => {

    const response = await getAllDefects(data)
    return response.data.items
})

export const searchDefects = createAsyncThunk('stock/searchDefects', async (payload) => {

    const response = await getDefects(payload)
    return response.data
})

export const newDefects = createAsyncThunk('stock/newDefects', async (payload, thunkAPI) => {

    try {
        const response = await addDefects(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateDefects = createAsyncThunk('stock/updateDefects', async (payload, thunkAPI) => {

    try {
        const response = await editDefects(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeDefects = createAsyncThunk('stock/removeDefects', async (data) => {

    const response = await deleteDefects(data)
    return {}
})




const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const defectsSlice = createSlice({
    name: "Defects",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchAllDefects.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllDefects.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllDefects.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        builder
            .addCase(searchDefects.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchDefects.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "search", status: "succeded" }
            })
            .addCase(searchDefects.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Defects :
        builder
            .addCase(newDefects.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newDefects.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newDefects.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Defects :
        builder
            .addCase(updateDefects.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateDefects.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateDefects.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Remove Defects :
        builder
            .addCase(removeDefects.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeDefects.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeDefects.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


    }
})

export default defectsSlice.reducer;