// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesPayment, deleteSalesPayment, editSalesPayment, getAllSalesPayment, getSalesPayment } from "../request/salesPayemntRequest";



export const fetchAllSalesPayment = createAsyncThunk('import/fetchAllSalesPayment', async (data) => {

    const response = await getAllSalesPayment(data)
    return response.data.items
})

export const searchSalesPayment = createAsyncThunk('import/searchSalesPayment', async (payload) => {

    const response = await getSalesPayment(payload)
    return response.data
})


export const newSalesPayment = createAsyncThunk('import/newSalesPayment', async (payload, thunkAPI) => {

    try {
        const response = await addSalesPayment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSalesPayment = createAsyncThunk('import/updateSalesPayment', async (payload, thunkAPI) => {

    try {
        const response = await editSalesPayment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeSalesPayment = createAsyncThunk('import/removeSalesPayment', async (payload) => {
    const response = await deleteSalesPayment(payload)
    return {}
})

const initialState = {
    info: [],
    bons: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesPaymentSlice = createSlice({
    name: "salesPaymentSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Payment :
        builder
            .addCase(fetchAllSalesPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesPayment.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Payment :
        builder
            .addCase(searchSalesPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesPayment.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })



        //New Sales Payment :
        builder
            .addCase(newSalesPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSalesPayment.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSalesPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Sales Payment :
        builder
            .addCase(updateSalesPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesPayment.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Sales Payment :
        builder
            .addCase(removeSalesPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesPayment.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


    }
})

export default salesPaymentSlice.reducer;