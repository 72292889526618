// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addSupplierPayment, copySupplierPayment, deleteSupplierPayment, editSupplierPayment, editSupplierPaymentStatus, getAllSupplierPayment, getSupplierPayment, getSupplierPaymentBySupplier } from "../request/supplierPaymentRequest";

export const fetchAllSupplierPayment = createAsyncThunk('purchase/fetchAllSupplierPayment', async () => {

    const response = await getAllSupplierPayment()
    return response.data.items
})

export const searchSupplierPayment = createAsyncThunk('purchase/searchSupplierPayment', async (payload) => {

    const response = await getSupplierPayment(payload)
    return response.data
})

export const searchSupplierPaymentBySupplier = createAsyncThunk('purchase/searchSupplierPaymentBySupplier', async (payload) => {

    const response = await getSupplierPaymentBySupplier(payload)
    return response.data.items
})

export const newSupplierPayment = createAsyncThunk('purchase/newSupplierPayment', async (payload, thunkAPI) => {

    try {
        const response = await addSupplierPayment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const changeSupplierPayment = createAsyncThunk('purchase/changeSupplierPayment', async (payload, thunkAPI) => {

    try {
        const response = await copySupplierPayment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSupplierPayment = createAsyncThunk('purchase/updateSupplierPayment', async (payload, thunkAPI) => {

    try {
        const response = await editSupplierPayment(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSupplierPaymentStatus = createAsyncThunk('purchase/updateSupplierPaymentStatus', async (payload, thunkAPI) => {

    try {
        const response = await editSupplierPaymentStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeSupplierPayment = createAsyncThunk('purchase/removeSupplierPayment', async (payload, thunkAPI) => {

    const response = await deleteSupplierPayment(payload)
    return {}
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const supplierPaymentSlice = createSlice({
    name: "payment",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Supplier Payments:

        builder
            .addCase(fetchAllSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSupplierPayment.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllSupplierPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Search Supplier Payment:
        builder
            .addCase(searchSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSupplierPayment.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSupplierPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Search Supplier Payment By Supplier:
        builder
            .addCase(searchSupplierPaymentBySupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSupplierPaymentBySupplier.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSupplierPaymentBySupplier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })


        //New  Supplier Payment :
        builder
            .addCase(newSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSupplierPayment.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newSupplierPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Copy  Supplier Payment :
        builder
            .addCase(changeSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "copy", errors: {} }
            })
            .addCase(changeSupplierPayment.fulfilled, (state, action) => {
                return { ...state, type: "copy", status: "succeded" }
            })
            .addCase(changeSupplierPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "copy", status: "failed" }
            })


        //Update  Supplier Payment :
        builder
            .addCase(updateSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSupplierPayment.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSupplierPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Update  Supplier Payment Status :
        builder
            .addCase(updateSupplierPaymentStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateSupplierPaymentStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateSupplierPaymentStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Remove  Supplier Payment :
        builder
            .addCase(removeSupplierPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSupplierPayment.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSupplierPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })

    }
})

export default supplierPaymentSlice.reducer;