// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addBillofLading, addBillofLadingPdf, deleteBillofLading, editBillofLading, getAllBillofLadings, getBillofLading } from "../request/blRequest";

export const fetchAllBillofLadings = createAsyncThunk('import/fetchAllBillofLadings', async () => {

    const response = await getAllBillofLadings()
    return response.data.items
})



export const searchBillofLading = createAsyncThunk('import/searchBillofLading', async (payload) => {

    const response = await getBillofLading(payload)
    return response.data
})

export const newBillofLading = createAsyncThunk('import/newBillofLading', async (payload, thunkAPI) => {

    try {
        const response = await addBillofLading(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateBillofLading = createAsyncThunk('import/updateBillofLading', async (payload, thunkAPI) => {

    try {
        const response = await editBillofLading(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeBillofLading = createAsyncThunk('import/removeBillofLading', async (payload) => {
    const response = await deleteBillofLading(payload)
    return {}
})

export const uploadBillofLadingPdf = createAsyncThunk('import/uploadBillofLadingPdf', async (payload, thunkAPI) => {

    try {
        const response = await addBillofLadingPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    bon: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const blSlice = createSlice({
    name: "bl",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Commercial Invoice :
        builder
            .addCase(fetchAllBillofLadings.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllBillofLadings.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllBillofLadings.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Commercial Invoice :
        builder
            .addCase(searchBillofLading.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchBillofLading.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchBillofLading.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })

        //New Commercial Invoice :
        builder
            .addCase(newBillofLading.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newBillofLading.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newBillofLading.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Bill of lading:
        builder
            .addCase(updateBillofLading.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBillofLading.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBillofLading.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Bill of lading:
        builder
            .addCase(removeBillofLading.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeBillofLading.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeBillofLading.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Bill of lading Pdf :
        builder
            .addCase(uploadBillofLadingPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadBillofLadingPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadBillofLadingPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default blSlice.reducer;
