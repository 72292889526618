import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPermission } from "../request/permissionRequest";


export const fetchPermission = createAsyncThunk('permissions/fetchPermission', async (data) => {

    const response = await getPermission(data)
    return response.data.items
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const permissionSlice = createSlice({
    name: "permission",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchPermission.pending, (state, action) => {
                return { ...state, status: "loading", type: "get" }
            })
            .addCase(fetchPermission.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchPermission.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

    }
})

export default permissionSlice.reducer;