// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addSalesCustomerBalance, createSalesCustomerBalance, deleteSalesCustomerBalance, editSalesCustomerBalance, getAllSalesCustomersBalance, getSalesCustomerBalance } from "../request/salesCustomerPrevBalance";



export const fetchAllSalesCustomerBalance = createAsyncThunk('sales/fetchAllSalesCustomerBalance', async (data) => {

    const response = await getAllSalesCustomersBalance(data)
    return response.data.items
})

export const searchSalesCustomerBalance = createAsyncThunk('sales/searchSalesCustomerBalance', async (payload) => {

    const response = await getSalesCustomerBalance(payload)
    return response.data
})


export const newSalesCustomerBalance = createAsyncThunk('sales/newSalesCustomerBalance', async (payload, thunkAPI) => {

    try {
        const response = await addSalesCustomerBalance(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateSalesCustomerBalance = createAsyncThunk('sales/updateSalesCustomerBalance', async (payload, thunkAPI) => {

    try {
        const response = await editSalesCustomerBalance(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeSalesCustomerBalance = createAsyncThunk('sales/removeSalesCustomerBalance', async (payload) => {
    const response = await deleteSalesCustomerBalance(payload)
    return {}
})


export const saveSalesCustomerBalance = createAsyncThunk('sales/saveSalesCustomerBalance', async (payload, thunkAPI) => {

    try {
        const response = await createSalesCustomerBalance(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesCustomerPrevBalanceSlice = createSlice({
    name: "salesCustomerPrevBalanceSlice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Customer Balance :
        builder
            .addCase(fetchAllSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesCustomerBalance.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Customer Balance:
        builder
            .addCase(searchSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesCustomerBalance.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Sales Customer Balance:
        builder
            .addCase(newSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newSalesCustomerBalance.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Sales Customer Balance:
        builder
            .addCase(updateSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesCustomerBalance.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })



        //Delete Sales Customer Balance :
        builder
            .addCase(removeSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesCustomerBalance.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


        //Save Sales Customer Balance :
        builder
            .addCase(saveSalesCustomerBalance.pending, (state, action) => {
                return { ...state, status: "loading", type: "save", errors: {} }
            })
            .addCase(saveSalesCustomerBalance.fulfilled, (state, action) => {
                return { ...state, type: "save", status: "succeded" }
            })
            .addCase(saveSalesCustomerBalance.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "save", status: "failed" }
            })


    }
})

export default salesCustomerPrevBalanceSlice.reducer;