// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAllImportSuppliers, addImportSupplier, getImportSupplier, editImportSupplier, deleteImportSupplier } from "../request/importSupplierRequest";

export const fetchAllImportSuppliers = createAsyncThunk('import/fetchAllImportSuppliers', async () => {

    const response = await getAllImportSuppliers()
    return response.data.items
})

export const searchImportSupplier = createAsyncThunk('import/searchImportSupplier', async (payload) => {

    const response = await getImportSupplier(payload)
    return response.data
})

export const newImportSupplier = createAsyncThunk('import/newImportSupplier', async (payload, thunkAPI) => {

    try {
        const response = await addImportSupplier(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateImportSupplier = createAsyncThunk('import/updateImportSupplier', async (payload, thunkAPI) => {

    try {
        const response = await editImportSupplier(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeImportSupplier = createAsyncThunk('import/removeImportSupplier', async (payload) => {
    const response = await deleteImportSupplier(payload)
    return {}
})


const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const importSupplierSlice = createSlice({
    name: "importSupplier",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Import Supplier :

        builder
            .addCase(fetchAllImportSuppliers.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllImportSuppliers.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllImportSuppliers.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach Import Supplier :

        builder
            .addCase(searchImportSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchImportSupplier.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchImportSupplier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Add Import Supplier:

        builder
            .addCase(newImportSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newImportSupplier.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newImportSupplier.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post", status: "failed" }
            })

        //Update PD :
        builder
            .addCase(updateImportSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateImportSupplier.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateImportSupplier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete PD :
        builder
            .addCase(removeImportSupplier.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeImportSupplier.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeImportSupplier.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })
    }
})

export default importSupplierSlice.reducer;