// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addCertificate, deleteCertificate, editCertificate, getAllCertificates } from "../request/certificateRequest";



export const fetchAllCertificates = createAsyncThunk('import/fetchAllCertificates', async (data) => {

    const response = await getAllCertificates(data)
    return response.data.items
})


export const newCertificate = createAsyncThunk('import/newCertificate', async (payload, thunkAPI) => {

    try {
        const response = await addCertificate(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateCertificate = createAsyncThunk('import/updateCertificate', async (payload, thunkAPI) => {

    try {
        const response = await editCertificate(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeCertificate = createAsyncThunk('import/removeCertificate', async (data) => {

    const response = await deleteCertificate(data)
    return {}
})




const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const certificateSlice = createSlice({
    name: "certificate",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchAllCertificates.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllCertificates.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllCertificates.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //New Certificate :
        builder
            .addCase(newCertificate.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newCertificate.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newCertificate.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Certificate :
        builder
            .addCase(updateCertificate.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateCertificate.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateCertificate.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Remove Certificate :
        builder
            .addCase(removeCertificate.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeCertificate.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeCertificate.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


    }
})

export default certificateSlice.reducer;