// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBillofMaterial, deleteBillofMaterial, editBillofMaterial, getAllBillofMaterials, getBillofMaterial } from "../request/BillofMaterialRequest";



export const fetchAllBillofMaterials = createAsyncThunk('BOM/fetchAllBillofMaterials', async () => {

    const response = await getAllBillofMaterials()
     return response.data
})



export const searchBillofMaterial = createAsyncThunk('BOM/searchBillofMaterial', async (payload) => {

    const response = await getBillofMaterial(payload)
    return response.data
})

export const newBillofMaterial = createAsyncThunk('BOM/newBillofMaterial', async (payload, thunkAPI) => {

    try {
        const response = await addBillofMaterial(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateBillofMaterial = createAsyncThunk('BOM/updateBillofMaterial', async (payload, thunkAPI) => {

    try {
        const response = await editBillofMaterial(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeBillofMaterial = createAsyncThunk('BOM/removeBillofMaterial', async (payload) => {
    const response = await deleteBillofMaterial(payload)
    return {}
})




const initialState = {
    info: [],
    bon: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const BillofMaterialSlice = createSlice({
    name: "Bom",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Bill of Matrial :
        builder
            .addCase(fetchAllBillofMaterials.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllBillofMaterials.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllBillofMaterials.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Seach Bill of Matrial :
        builder
            .addCase(searchBillofMaterial.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchBillofMaterial.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(searchBillofMaterial.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })

        //New Bill of Matrial :
        builder
            .addCase(newBillofMaterial.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newBillofMaterial.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newBillofMaterial.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Bill of lading:
        builder
            .addCase(updateBillofMaterial.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBillofMaterial.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBillofMaterial.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Bill of lading:
        builder
            .addCase(removeBillofMaterial.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeBillofMaterial.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeBillofMaterial.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        
    }
})

export default BillofMaterialSlice.reducer;
