// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addCommercialInvoice, addCommercialInvoicePdf, deleteCommercialInvoice, editCommercialInvoice, getAllCommercialInvoices, getCommercialInvoice, getCommercialInvoiceBon } from "../request/commercialInvoiceRequest";

export const fetchAllCommercialInvoices = createAsyncThunk('import/fetchAllCommercialInvoices', async () => {

    const response = await getAllCommercialInvoices()
    return response.data.items
})

export const fetchCommercialInvoiceBon = createAsyncThunk('import/fetchCommercialInvoiceBon', async (payload) => {

    const response = await getCommercialInvoiceBon(payload)
    return response.data.items
})

export const searchCommercialInvoice = createAsyncThunk('import/searchCommercialInvoice', async (payload) => {

    const response = await getCommercialInvoice(payload)
    return response.data
})

export const newCommercialInvoice = createAsyncThunk('import/newCommercialInvoice', async (payload, thunkAPI) => {

    try {
        const response = await addCommercialInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateCommercialInvoice = createAsyncThunk('import/updateCommercialInvoice', async (payload, thunkAPI) => {

    try {
        const response = await editCommercialInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeCommercialInvoice = createAsyncThunk('import/removeCommercialInvoice', async (payload) => {
    const response = await deleteCommercialInvoice(payload)
    return {}
})


export const uploadCommercialInvoicePdf = createAsyncThunk('import/uploadCommercialInvoicePdf', async (payload, thunkAPI) => {

    try {
        const response = await addCommercialInvoicePdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    bon: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const commercialInvoiceSlice = createSlice({
    name: "commercialInvoice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Commercial Invoice :
        builder
            .addCase(fetchAllCommercialInvoices.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllCommercialInvoices.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllCommercialInvoices.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Fetch  Commercial Invoice Bon :
        builder
            .addCase(fetchCommercialInvoiceBon.pending, (state, action) => {
                return { ...state, status: "loading", type: "get_bon", errors: {} }
            })
            .addCase(fetchCommercialInvoiceBon.fulfilled, (state, action) => {
                return { ...state, bon: [...action.payload], type: "get_bon", status: "succeded" }
            })
            .addCase(fetchCommercialInvoiceBon.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "get_bon", status: "failed" }
            })

        //Seach Commercial Invoice :
        builder
            .addCase(searchCommercialInvoice.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchCommercialInvoice.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchCommercialInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Commercial Invoice :
        builder
            .addCase(newCommercialInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newCommercialInvoice.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newCommercialInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Commercial Invoice:
        builder
            .addCase(updateCommercialInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateCommercialInvoice.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateCommercialInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Commercial Invoice:
        builder
            .addCase(removeCommercialInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeCommercialInvoice.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeCommercialInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


        //Upload Commercial Invoice Pdf :
        builder
            .addCase(uploadCommercialInvoicePdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadCommercialInvoicePdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadCommercialInvoicePdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default commercialInvoiceSlice.reducer;