// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addReception, deleteReception, editReception, getAllReceptions, getReception, getReceptionBons } from "../request/receptionRequest";

export const fetchAllReceptions = createAsyncThunk('import/fetchAllReceptions', async () => {

    const response = await getAllReceptions()
    return response.data.items
})

export const searchReception = createAsyncThunk('import/searchReception', async (payload) => {

    const response = await getReception(payload)
    return response.data
})


export const searchReceptionBon = createAsyncThunk('import/searchReceptionBon', async () => {

    const response = await getReceptionBons()
    return response.data.items

})


export const newReception = createAsyncThunk('import/newReception', async (payload, thunkAPI) => {

    try {
        const response = await addReception(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateReception = createAsyncThunk('import/updateReception', async (payload, thunkAPI) => {

    try {
        const response = await editReception(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeReception = createAsyncThunk('import/removeReception', async (payload) => {
    const response = await deleteReception(payload)
    return {}
})


export const updateReceptionStatus = createAsyncThunk('import/updateReceptionStatus', async (payload, thunkAPI) => {

    try {
        const response = await editReceptionStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})





const initialState = {
    info: [],
    bons: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const receptionSlice = createSlice({
    name: "reception",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Reception :
        builder
            .addCase(fetchAllReceptions.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllReceptions.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllReceptions.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        // Seach Reception :
        builder
            .addCase(searchReception.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchReception.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchReception.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })

        //Search Bons :
        builder
            .addCase(searchReceptionBon.pending, (state, action) => {
                return { ...state, status: "loading", type: "getBon", errors: {} }
            })
            .addCase(searchReceptionBon.fulfilled, (state, action) => {
                return { ...state, bons: action.payload, type: "getBon", status: "succeded" }
            })
            .addCase(searchReceptionBon.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "getBon", status: "failed" }
            })

        //New Reception :
        builder
            .addCase(newReception.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newReception.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newReception.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit Reception:
        builder
            .addCase(updateReception.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateReception.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateReception.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Reception:
        builder
            .addCase(removeReception.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeReception.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeReception.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })




    }
})

export default receptionSlice.reducer;