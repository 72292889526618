// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  addSalesLoading, addSalesLoadingPdf, deleteSalesLoading, editSalesLoading, getAllSalesLoading, getSalesLoading } from "../request/salesLoadingRequest";



export const fetchAllSalesLoading = createAsyncThunk('sale/fetchAllSalesLoading', async (data) => {

    const response = await getAllSalesLoading(data)
    return response.data.items
})

export const searchSalesLoading = createAsyncThunk('sale/searchSalesLoading', async (payload) => {

    const response = await getSalesLoading(payload)
    return response.data
})

export const createSalesLoading = createAsyncThunk('sale/createSalesLoading', async (payload, thunkAPI) => {

    try {
        const response = await addSalesLoading(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateSalesLoading = createAsyncThunk('import/updateSalesLoading', async (payload, thunkAPI) => {

    try {
        const response = await editSalesLoading(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeSalesLoading = createAsyncThunk('import/removeSalesLoading', async (payload) => {
    const response = await deleteSalesLoading(payload)
    return {}
})

export const uploadSalesLoadingPdf = createAsyncThunk('import/uploadSalesLoadingPdf', async (payload, thunkAPI) => {

    try {
        const response = await addSalesLoadingPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],

    default: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const salesLoadingSlice = createSlice({
    name: "salesLoading",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Sales Loading :
        builder
            .addCase(fetchAllSalesLoading.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllSalesLoading.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllSalesLoading.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Sales Loading :
        builder
            .addCase(searchSalesLoading.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchSalesLoading.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchSalesLoading.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Create  Sales Loading :
        builder
            .addCase(createSalesLoading.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(createSalesLoading.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(createSalesLoading.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "post", status: "failed" }
            })

        //Edit Sales Loading  :
        builder
            .addCase(updateSalesLoading.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateSalesLoading.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateSalesLoading.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete Sales Loading :
        builder
            .addCase(removeSalesLoading.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeSalesLoading.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeSalesLoading.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Sales Loading Pdf :
        builder
            .addCase(uploadSalesLoadingPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadSalesLoadingPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadSalesLoadingPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
       
    }
})

export default salesLoadingSlice.reducer;