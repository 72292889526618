import axios from "axios";
import config from "../../_config.json"

const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}

export const getAllSalesTransaction = async () => {

    let token = localStorage.getItem('token')


    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction`
    })
};


export const getSalesTransaction = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/${payload}`
    })
};

export const addSalesTransactionImpaye = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/impaye`,
        data: payload
    })
};

export const addSalesTransactionRepresentation = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/representation`,
        data: payload
    })
};

export const addSalesTransactionRestitution = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/restitution`,
        data: payload
    })
};

export const addSalesTransactionContreImpaye = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/contreImpaye`,
        data: payload
    })
};

export const addSalesTransactionPdf = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/${payload.id}/pdf`,
        data: payload.data
    })
};

export const editStatusTransaction = async (payload) => {
    let token = localStorage.getItem('token');
    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/transaction/${payload.id}`,
        data: { ...payload.data }
    });
};
