// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addImportPaymentPdf, addImportPayments, deleteImportPayments, editImportPayments, editImportPaymentStatus, getAllImportPayments, getImportPayment, saveImportPayments } from "../request/importPaymentRequest";



export const fetchAllImportPayments = createAsyncThunk('import/fetchAllImportPayments', async (data) => {

    const response = await getAllImportPayments(data)
    return response.data.items
})

export const searchImportPayment = createAsyncThunk('import/searchImportPayment', async (payload) => {

    const response = await getImportPayment(payload)
    return response.data
})

export const newImportPayment = createAsyncThunk('import/newImportPayment', async (payload, thunkAPI) => {

    try {
        const response = await addImportPayments(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const saveImportPayment = createAsyncThunk('import/saveImportPayment', async (payload, thunkAPI) => {

    try {
        const response = await saveImportPayments(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateImportPayment = createAsyncThunk('import/updateImportPayment', async (payload, thunkAPI) => {

    try {
        const response = await editImportPayments(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeImportPayment = createAsyncThunk('import/removeImportPayment', async (payload) => {
    const response = await deleteImportPayments(payload)
    return {}
})


export const updateImportPaymentStatus = createAsyncThunk('import/updateImportPaymentStatus', async (payload, thunkAPI) => {

    try {
        const response = await editImportPaymentStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadImportPaymentPdf = createAsyncThunk('import/uploadImportPaymentPdf', async (payload, thunkAPI) => {

    try {
        const response = await addImportPaymentPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const importPaymentSlice = createSlice({
    name: "importPayment",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Import Payment :
        builder
            .addCase(fetchAllImportPayments.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllImportPayments.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllImportPayments.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Import Payment :
        builder
            .addCase(searchImportPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchImportPayment.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchImportPayment.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Import Payment :
        builder
            .addCase(newImportPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newImportPayment.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newImportPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //save Import Payment :
        builder
            .addCase(saveImportPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "save", errors: {} }
            })
            .addCase(saveImportPayment.fulfilled, (state, action) => {
                return { ...state, type: "save", status: "succeded" }
            })
            .addCase(saveImportPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "save", status: "failed" }
            })

        //Update  Import Payment :
        builder
            .addCase(updateImportPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateImportPayment.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateImportPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete  Import Payment :
        builder
            .addCase(removeImportPayment.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeImportPayment.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeImportPayment.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Import Payment Status :
        builder
            .addCase(updateImportPaymentStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateImportPaymentStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateImportPaymentStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Upload Import Payment Pdf :
        builder
            .addCase(uploadImportPaymentPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadImportPaymentPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadImportPaymentPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default importPaymentSlice.reducer;