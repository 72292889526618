
//@ts-nocheck
import axios from "axios";
import config from "../../_config.json"


const getHeaders = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}
// **Add Comment**
export const addComment = async (payload) => {
  
    
    let token = localStorage.getItem('token');
 
    return await axios.request({
      method: "post",
      headers: getHeaders(token),
      withCredentials: true,
      url: `${config.apiUrl}/Comment/${payload.DocummentId}/Comments`,
      data: payload,
    });
  };

  // **delete  Comments**
  export const deleteComment = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Comment/${payload}`,
    })
    
};

 // **edit  Comments**
export const editComment = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Comment/${payload.id}`,
        data: payload.data
    })
};

 // **Get  Comments**
export const getAllComments = async (payload) => {

    let token = localStorage.getItem('token')
    
    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/Comment/search?docummentId=${payload.DocummentId}&Collection=${payload.Collection}`
    })
};
