//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { editStock, getStockbyQuery, getStockbyWarehouse, getStocks } from "../request/stockRequest";

export const fetchAllStocks = createAsyncThunk('stock/fetchAllStocks', async (payload) => {
    var response = await getStocks(payload)
    return response.data.items
})

export const fetchStocksbyWarehouse = createAsyncThunk('stock/fetchStocksbyWarehouse', async (payload) => {
    var response = await getStockbyWarehouse(payload)
    return response.data.items
})

export const fetchStocksbyQuery = createAsyncThunk('stock/fetchStocksbyQuery', async (payload) => {
    var response = await getStockbyQuery(payload)
    return response.data
})

export const updateStock = createAsyncThunk('stock/updateStock', async (payload) => {
    try {
        const response = await editStock(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }
})

const initialState = {
    info: [],
    status: 'idle',
    type: '',
    errors: {}
}

//Reducers
const stockSlice = createSlice({
    name: "stock",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch Stock :
        builder
            .addCase(fetchAllStocks.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllStocks.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllStocks.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Fetch Stock by warehouse :
        builder
            .addCase(fetchStocksbyWarehouse.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(fetchStocksbyWarehouse.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "search", status: "succeded" }
            })
            .addCase(fetchStocksbyWarehouse.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })


        builder
            .addCase(fetchStocksbyQuery.pending, (state, action) => {
                return { ...state, status: "loading", type: "query", errors: {} }
            })
            .addCase(fetchStocksbyQuery.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "query", status: "succeded" }
            })
            .addCase(fetchStocksbyQuery.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "query", status: "failed" }
            })

        builder
            .addCase(updateStock.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateStock.fulfilled, (state, action) => {
                return { ...state, status: "succeded", type: "put" }
            })
            .addCase(updateStock.rejected, (state, action) => {
                return { ...state, type: "put", status: "failed" }
            })
    }
})

export default stockSlice.reducer;
