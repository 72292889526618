//@ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile, editProfile, uploadImage, createProfile, editProfileStatus } from "../request/profileRequest";

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (data, thunkAPI) => {

    const response = await getProfile(data)
    return response.data
})

export const newProfile = createAsyncThunk('profile/newProfile', async (data, thunkAPI) => {

    try {
        const response = await createProfile()
        return thunkAPI.fulfillWithValue(response.data)
    } catch (err) {
        return thunkAPI.rejectWithValue({ e });
    }
})


export const updateProfile = createAsyncThunk('profile/updateProfile', async (payload) => {
console.log(payload.data.caisse)
    const response = await editProfile({ id: payload.id, data: { firstName: payload.data.firstName, name: payload.data.name, email: payload.data.email, Caisse:payload.data.caisse, dateOfBirth: new Date(payload.data.dateOfBirth) } })

    return { firstName: payload.data.firstName, name: payload.data.name, email: payload.data.email, Caisse:payload.data.caisse, dateOfBirth: payload.data.dateOfBirth }

})

export const updateProfileStatus = createAsyncThunk('profile/updateProfileStatus', async (payload) => {

    const response = await editProfileStatus(payload)

    return {}

})

export const updateImage = createAsyncThunk('profile/updateImage', async (payload, thunkAPI) => {

    try {

        const response = await uploadImage(payload)

        return thunkAPI.fulfillWithValue(response.data)

    } catch (err) {
       
        return thunkAPI.rejectWithValue({ err });
    }

})

const initialState = {
    info: {
        id: null,
        firstName: '',
        name: '',
        email: '',
        dateOfBirth: null,
        position: '',
        imageUrl: null,
        status: '',
        permissions: []
    },
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        builder
            .addCase(fetchProfile.pending, (state, action) => {
                return { ...state, status: "loading", type: "get" }
            })
            .addCase(fetchProfile.fulfilled, (state, action) => {
                return { ...state, info: { ...action.payload }, type: "get", status: "succeded" }
            })
            .addCase(fetchProfile.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })



        //Create Ptofile :
        builder
            .addCase(newProfile.pending, (state, action) => {
                return { ...state, status: "loading", type: "post" }
            })
            .addCase(newProfile.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newProfile.rejected, (state, action) => {
                return { ...state, type: "post", status: "failed" }
            })

        //Update Ptofile :
        builder
            .addCase(updateProfile.pending, (state, action) => {
                return { ...state, status: "loading", type: "put" }
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                return { ...state, info: { ...state.info, ...action.payload }, type: "put", status: "succeded" }
            })
            .addCase(updateProfile.rejected, (state, action) => {
                return { ...state, type: "put", status: "failed" }
            })

        //Update Profile Status:
        builder
            .addCase(updateProfileStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "putStatus" }
            })
            .addCase(updateProfileStatus.fulfilled, (state, action) => {
                return { ...state, type: "putStatus", status: "succeded" }
            })
            .addCase(updateProfileStatus.rejected, (state, action) => {
                return { ...state, type: "putStatus", status: "failed" }
            })


        //Update Image :
        builder
            .addCase(updateImage.pending, (state, action) => {
                return { ...state, status: "loading", type: "image" }
            })
            .addCase(updateImage.fulfilled, (state, action) => {
                state.info.imageUrl = action.payload
                state.type = "image"
                state.status = "succeded"
            })
            .addCase(updateImage.rejected, (state, action) => {
                return { ...state, type: "image", status: "failed" }
            })

    }
})

export default profileSlice.reducer;