import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, tokenRequest } from "./_authConfig";

import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/styles/feather/feather.css'
import './assets/styles/mdi/materialdesignicons.min.css'
import './assets/styles/themify/themify-icons.css'
import './assets/styles/typicons/typicons.css'
import './assets/styles/simple-line/simple-line-icons.css'
import './assets/styles/style.css'
import { Provider } from "react-redux";
import { makeStore } from './redux/configureStore'

const msalInstance = new PublicClientApplication(msalConfig);


const accounts = msalInstance.getAllAccounts()



const store = makeStore()

ReactDOM.render(
  <React.StrictMode>

    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>

  </React.StrictMode >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
