import axios from "axios";
import config from "../../_config.json"


const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}



export const getStocks = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/stock?year=${payload}`
    })
};

export const getStockbyWarehouse = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/stock/search?warehouseId=${payload.warehouse}&year=${payload.year}`
    })
};

export const getStockbyQuery = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/stock/query?warehouse=${payload.warehouse}&product=${payload.product}`
    })
};

export const editStock = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        data: {},
        url: `${config.apiUrl}/stock?Warehouse=${payload.warehouse}&Year=${payload.year}`

    })
};