// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addProduct, deleteProduct, getAllProducts, editProduct } from "../request/productRequest";

export const fetchAllProducts = createAsyncThunk('product/fetchAllProducts', async () => {

    const response = await getAllProducts()
    return response.data.items
})

export const newProduct = createAsyncThunk('product/newProduct', async (payload, thunkAPI) => {

    try {
        const response = await addProduct(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateProduct = createAsyncThunk('product/updateProduct', async (payload, thunkAPI) => {

    try {
        const response = await editProduct(payload)

        return thunkAPI.fulfillWithValue({})
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const removeProduct = createAsyncThunk('warehouse/removeProduct', async (data) => {
    var response = await deleteProduct(data)
    return {}
})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const productSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All Families :

        builder
            .addCase(fetchAllProducts.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllProducts.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //New Product :
        builder
            .addCase(newProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newProduct.fulfilled, (state, action) => {
                return {
                    ...state, info: [action.payload, ...state.info], type: "post", status: "succeded"
                }
            })
            .addCase(newProduct.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update Product :
        builder
            .addCase(updateProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateProduct.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateProduct.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Remove Product :
        builder
            .addCase(removeProduct.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeProduct.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeProduct.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "delete", status: "failed" }
            })


    }
})

export default productSlice.reducer;