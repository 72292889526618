// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addImportEngagementPdf, addImportEngagements, deleteImportEngagements, editImportEngagements, editImportEngagementStatus, getAllImportEngagements, getImportEngagement, getImportEngagementByInvoice } from "../request/importEngagementRequest";



export const fetchAllImportEngagements = createAsyncThunk('import/fetchAllImportEngagements', async (data) => {

    const response = await getAllImportEngagements(data)
    return response.data.items
})

export const searchImportEngagementByInvoice = createAsyncThunk('import/searchImportEngagementByInvoice', async (payload) => {

    const response = await getImportEngagementByInvoice(payload)
    return response.data.items
})


export const searchImportEngagement = createAsyncThunk('import/searchImportEngagement', async (payload) => {

    const response = await getImportEngagement(payload)
    return response.data
})

export const newImportEngagement = createAsyncThunk('import/newImportEngagement', async (payload, thunkAPI) => {

    try {
        const response = await addImportEngagements(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateImportEngagement = createAsyncThunk('import/updateImportEngagement', async (payload, thunkAPI) => {

    try {
        const response = await editImportEngagements(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeImportEngagement = createAsyncThunk('import/removeImportEngagement', async (payload) => {
    const response = await deleteImportEngagements(payload)
    return {}
})


export const updateImportEngagementStatus = createAsyncThunk('import/updateImportEngagementStatus', async (payload, thunkAPI) => {

    try {
        const response = await editImportEngagementStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadImportEngagementPdf = createAsyncThunk('import/uploadImportEngagementPdf', async (payload, thunkAPI) => {

    try {
        const response = await addImportEngagementPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const importEngagementSlice = createSlice({
    name: "importEngagement",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Import Engagement :
        builder
            .addCase(fetchAllImportEngagements.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllImportEngagements.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllImportEngagements.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })


        //Fetch Import Engagement by Invoice :
        builder
            .addCase(searchImportEngagementByInvoice.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "get", errors: {} }
            })
            .addCase(searchImportEngagementByInvoice.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(searchImportEngagementByInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "get", status: "failed" }
            })

        //Seach  Import Engagement :
        builder
            .addCase(searchImportEngagement.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchImportEngagement.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchImportEngagement.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //New Import Engagement :
        builder
            .addCase(newImportEngagement.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newImportEngagement.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newImportEngagement.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Update  Import Engagement :
        builder
            .addCase(updateImportEngagement.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateImportEngagement.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateImportEngagement.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })


        //Delete  Import Engagement :
        builder
            .addCase(removeImportEngagement.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeImportEngagement.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeImportEngagement.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Update Import Engagement Status :
        builder
            .addCase(updateImportEngagementStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateImportEngagementStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateImportEngagementStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Upload Import Engagement Pdf :
        builder
            .addCase(uploadImportEngagementPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadImportEngagementPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadImportEngagementPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default importEngagementSlice.reducer;