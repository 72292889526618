// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addBank, deleteBank, editBank, getAllBanks, getBank, getDefaultBank, addBankPdf } from "../request/bankRequest";



export const fetchAllBanks = createAsyncThunk('import/fetchAllBanks', async (data) => {

    const response = await getAllBanks(data)
    return response.data.items
})

export const searchBank = createAsyncThunk('import/searchBank', async (payload) => {

    const response = await getBank(payload)
    return response.data
})

export const searchDefaultBank = createAsyncThunk('import/searchDefaultBank', async (payload) => {

    const response = await getDefaultBank(payload)
    return response.data
})

export const newBank = createAsyncThunk('import/newBank', async (payload, thunkAPI) => {

    try {
        const response = await addBank(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


export const updateBank = createAsyncThunk('import/updateBank', async (payload, thunkAPI) => {

    try {
        const response = await editBank(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeBank = createAsyncThunk('import/removeBank', async (payload) => {
    const response = await deleteBank(payload)
    return {}
})

export const uploadBankPdf = createAsyncThunk('import/uploadBankPdf', async (payload, thunkAPI) => {

    try {
        const response = await addBankPdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})


const initialState = {
    info: [],
    default: [],
    status: 'idle',
    type: null,
    errors: {}
}



//Reducers
const bankSlice = createSlice({
    name: "bank",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {



        //Fetch Bank :
        builder
            .addCase(fetchAllBanks.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllBanks.fulfilled, (state, action) => {
                return { ...state, info: action.payload, type: "get", status: "succeded" }
            })
            .addCase(fetchAllBanks.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach  Bank :
        builder
            .addCase(searchBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "search", errors: {} }
            })
            .addCase(searchBank.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchBank.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "search", status: "failed" }
            })

        //Seach Default Bank :
        builder
            .addCase(searchDefaultBank.pending, (state, action) => {
                return { ...state, default: [], status: "loading", type: "default", errors: {} }
            })
            .addCase(searchDefaultBank.fulfilled, (state, action) => {
                return { ...state, default: [action.payload], type: "default", status: "succeded" }
            })
            .addCase(searchDefaultBank.rejected, (state, action) => {
                return { ...state, default: [], errors: action.payload, type: "default", status: "failed" }
            })

        //New Bank :
        builder
            .addCase(newBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newBank.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newBank.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit bank :
        builder
            .addCase(updateBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateBank.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateBank.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete bank :
        builder
            .addCase(removeBank.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeBank.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeBank.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })

        //Upload Bank Pdf :
        builder
            .addCase(uploadBankPdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadBankPdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadBankPdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })
    }
})

export default bankSlice.reducer;