// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { addProformalInvoice, addProformalInvoicePdf, deleteProformalInvoice, editProformalInvoice, editProformalInvoiceStatus, getAllProformalInvoices, getProformalInvoice, getProformalInvoiceBon } from "../request/ProformalInvoiceRequest";

export const fetchAllProformalInvoices = createAsyncThunk('import/fetchAllProformalInvoices', async () => {

    const response = await getAllProformalInvoices()
    return response.data.items
})

export const searchProformalInvoice = createAsyncThunk('import/searchProformalInvoice', async (payload) => {

    const response = await getProformalInvoice(payload)
    return response.data
})

export const fetchProformalInvoiceBon = createAsyncThunk('import/fetchProformalInvoiceBon', async (payload) => {

    const response = await getProformalInvoiceBon(payload)
    return response.data.items
})


export const newProformalInvoice = createAsyncThunk('import/newProformalInvoice', async (payload, thunkAPI) => {

    try {
        const response = await addProformalInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const updateProformalInvoice = createAsyncThunk('import/updateProformalInvoice', async (payload, thunkAPI) => {

    try {
        const response = await editProformalInvoice(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const removeProformalInvoice = createAsyncThunk('import/removeProformalInvoice', async (payload) => {
    const response = await deleteProformalInvoice(payload)
    return {}
})


export const updateProformalInvoiceStatus = createAsyncThunk('import/updateProformalInvoiceStatus', async (payload, thunkAPI) => {

    try {
        const response = await editProformalInvoiceStatus(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})

export const uploadProformalInvoicePdf = createAsyncThunk('import/uploadProformalInvoicePdf', async (payload, thunkAPI) => {

    try {
        const response = await addProformalInvoicePdf(payload)

        return thunkAPI.fulfillWithValue(payload)
    }
    catch (err) {
        return thunkAPI.rejectWithValue({ error: err.response.data });
    }

})



const initialState = {
    info: [],
    status: 'idle',
    type: null,
    errors: {}
}

//Reducers
const proformalInvoiceSlice = createSlice({
    name: "proformalInvoice",
    initialState: initialState,
    reducers: {},

    extraReducers(builder) {

        //Fetch All ProformalInvoice :
        builder
            .addCase(fetchAllProformalInvoices.pending, (state, action) => {
                return { ...state, status: "loading", type: "get", errors: {} }
            })
            .addCase(fetchAllProformalInvoices.fulfilled, (state, action) => {
                return { ...state, info: [...action.payload], type: "get", status: "succeded" }
            })
            .addCase(fetchAllProformalInvoices.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get", status: "failed" }
            })

        //Seach ProformalInvoice :
        builder
            .addCase(searchProformalInvoice.pending, (state, action) => {
                return { ...state, info: [], status: "loading", type: "search", errors: {} }
            })
            .addCase(searchProformalInvoice.fulfilled, (state, action) => {
                return { ...state, info: [action.payload], type: "search", status: "succeded" }
            })
            .addCase(searchProformalInvoice.rejected, (state, action) => {
                return { ...state, errors: action.payload, info: [], type: "search", status: "failed" }
            })

        //Fetch  Proforma Invoice Bon :
        builder
            .addCase(fetchProformalInvoiceBon.pending, (state, action) => {
                return { ...state, status: "loading", type: "get_bon", errors: {} }
            })
            .addCase(fetchProformalInvoiceBon.fulfilled, (state, action) => {
                return { ...state, bon: [...action.payload], type: "get_bon", status: "succeded" }
            })
            .addCase(fetchProformalInvoiceBon.rejected, (state, action) => {
                return { ...state, errors: action.payload, type: "get_bon", status: "failed" }
            })

        //New ProformalInvoice :
        builder
            .addCase(newProformalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "post", errors: {} }
            })
            .addCase(newProformalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "post", status: "succeded" }
            })
            .addCase(newProformalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "post", status: "failed" }
            })

        //Edit ProformalInvoice:
        builder
            .addCase(updateProformalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "put", errors: {} }
            })
            .addCase(updateProformalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "put", status: "succeded" }
            })
            .addCase(updateProformalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put", status: "failed" }
            })

        //Delete ProformalInvoice:
        builder
            .addCase(removeProformalInvoice.pending, (state, action) => {
                return { ...state, status: "loading", type: "delete", errors: {} }
            })
            .addCase(removeProformalInvoice.fulfilled, (state, action) => {
                return { ...state, type: "delete", status: "succeded" }
            })
            .addCase(removeProformalInvoice.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "delete", status: "failed" }
            })


        //Update ProformalInvoice Status :
        builder
            .addCase(updateProformalInvoiceStatus.pending, (state, action) => {
                return { ...state, status: "loading", type: "put_status", errors: {} }
            })
            .addCase(updateProformalInvoiceStatus.fulfilled, (state, action) => {
                return { ...state, type: "put_status", status: "succeded" }
            })
            .addCase(updateProformalInvoiceStatus.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "put_status", status: "failed" }
            })

        //Upload ProformalInvoice Pdf :
        builder
            .addCase(uploadProformalInvoicePdf.pending, (state, action) => {
                return { ...state, status: "loading", type: "upload", errors: {} }
            })
            .addCase(uploadProformalInvoicePdf.fulfilled, (state, action) => {
                return { ...state, type: "upload", status: "succeded" }
            })
            .addCase(uploadProformalInvoicePdf.rejected, (state, { payload }) => {
                return { ...state, errors: payload.error.errors, type: "upload", status: "failed" }
            })

    }
})

export default proformalInvoiceSlice.reducer;