import axios from "axios";
import config from "../../_config.json"

const getHeaders: any = (token) => {
    return {
        'Authorization': 'Bearer ' + token,
        'x-tenant-id': localStorage.getItem('tenantId'),
        'Content-Type': 'application/json'
    }
}

export const getAllSalesCustomerel = async () => {

    let token = localStorage.getItem('token')


    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer`
    })
};

export const getSalesCustomer = async (payload) => {

    let token = localStorage.getItem('token')

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer/${payload}`
    })
};

export const addSalesCustomer = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer`,
        data: payload
    })

};


export const editSalesCustomer = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer/${payload.id}`,
        data: { ...payload.data }
    })

};

export const deleteSalesCustomer = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer/${payload}`,
    })

};


export const editSalesCustomerStatus = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer/${payload.id}/status`,
        data: { ...payload.data }
    })

};

export const editSalesCustomerPermissions = async (payload) => {
    let token = localStorage.getItem('token')

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/SalesCustomer/${payload.id}/permissions`,
        data: { ...payload.data }
    })

};

