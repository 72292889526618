// @ts-nocheck
import axios from "axios";
import config from "../../_config.json";

const getHeaders = (token) => ({
    'Authorization': 'Bearer ' + token,
    'x-tenant-id': localStorage.getItem('tenantId'),
    'Content-Type': 'application/json'
});

// Get all Batches
export const getAllBatches = async () => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "get",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/batch`
    });
};

// Create a new Batch
export const addBatch = async (payload) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "post",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/batch`,
        data: payload
    });
};

// Update an existing Batch
export const editBatch = async (payload) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "put",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/batch/${payload.id}`,
        data: payload
    });
};

// Delete a Batch
export const deleteBatch = async (id) => {
    let token = localStorage.getItem('token');

    return await axios.request({
        method: "delete",
        headers: getHeaders(token),
        withCredentials: true,
        url: `${config.apiUrl}/batch/${id}`
    });
};
